import React from "react";

const Lines = () => {
  return (
    <div className="h-[140px] flex items-center justify-center w-full relative mt-5">
      <div className="h-[80px] flex justify-center w-[calc(100%_-_23%)] absolute top-0 left-1/2 -translate-x-1/2">
        <div className="w-[23%] flex justify-center relative">
          <div className="h-full w-[3px] bg-[#3070D7] flex flex-col justify-end relative z-10"></div>
          <div className="w-[20px] h-[20px] rounded-full border-[2px] border-white bg-[#3070D7] absolute -top-2 left-1/2 -translate-x-1/2 ml-[.7px]"></div>
        </div>
      </div>

      <div className="h-[60px] mt-[80px] border-[3px] border-[#3070D7] border-b-0 flex justify-between rounded-[8px] rounded-br-none rounded-bl-none w-[calc(100%_-_23%)] absolute top-0 left-1/2 -translate-x-1/2"></div>
      <div className="h-[60px] mt-[80px] flex justify-between w-full absolute top-0 left-0">
        <div className="w-[23%] flex justify-center relative">
          <div className="w-[20px] h-[20px] rounded-full border-[2px] border-white bg-[#3070D7] absolute -bottom-2 left-1/2 -translate-x-1/2 ml-[1.5px]"></div>
        </div>
        <div className="w-[23%] flex justify-center relative">
          <div className="h-full w-[3px] bg-[#3070D7] flex flex-col justify-end relative z-10"></div>
          <div className="w-[20px] h-[20px] rounded-full border-[2px] border-white bg-[#3070D7] absolute -bottom-2 left-1/2 -translate-x-1/2"></div>
        </div>
        <div className="w-[23%] flex justify-center relative">
          <div className="h-full w-[3px] bg-[#3070D7] flex flex-col justify-end relative z-10"></div>
          <div className="w-[20px] h-[20px] rounded-full border-[2px] border-white bg-[#3070D7] absolute -bottom-2 left-1/2 -translate-x-1/2"></div>
        </div>
        <div className="w-[23%] flex justify-center relative">
          <div className="w-[20px] h-[20px] rounded-full border-[2px] border-white bg-[#3070D7] absolute -bottom-2 left-1/2 -translate-x-1/2 -ml-[1.51px]"></div>
        </div>
      </div>
    </div>
  );
};

export default Lines;
