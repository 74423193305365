import React, { useContext, useEffect } from "react";
import Icons from "./Icons";
import User from "./User";
import {
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";
import Select from "react-select";
import { Context } from "../../../App";

const data = [
  {
    label: "notification",
    path: "../assets/menu-icons/Bell.png",
  },
];

const Header = ({}) => {
  const { setActive } = useOrganizationList();
  const { organizationList } = useOrganizationList();
  const { organization, ...rest } = useOrganization();

  const { hasOrganizationLoaded, setHasOrganizationLoaded } =
    useContext(Context);
  const { organization: currentOrganization, isLoaded } = useOrganization();
  const { setSelectedImage, getAllOrganization } = useContext(Context);

  const handleOrgChange = (e) => {
    setActive({ organization: e.value });
    setSelectedImage(null);
    setHasOrganizationLoaded(true);
    // if (!currentOrganization) {
    //   setActive({ organization: organizationList?.[0]?.organization?.id });
    // }
  };

  // useEffect(() => {
  //   if (!currentOrganization) {
  //   }
  // }, [currentOrganization]);

  return (
    <div className="h-[90px] bg-slate-600  border-b border-b-borderColor flex items-center justify-end px-[32px] gap-4">
      <div className="flex items-center ">
        {/* {organizationList?.length > 0 && (
          <div className="text-primaryColor font-semibold  mr-10 p-1 rounded-md flex items-center gap-2">
            <div className="w-3 bg-green h-3 rounded-full"></div>
            {currentOrganization?.name}
          </div>
        )} */}
        {organizationList?.length > 0 && (
          <Select
            options={createOrganizationOptions(organizationList)}
            onChange={handleOrgChange}
            value={{ value: organization?.id, label: organization?.name }}
          />
        )}

        {data.map((item, i) => (
          <Icons key={i} data={item} />
        ))}
        <User />
      </div>
    </div>
  );
};

export default Header;

function createOrganizationOptions(organizationList) {
  return organizationList?.map(({ organization }) => ({
    value: organization.id,
    label: organization.name,
  }));
}
