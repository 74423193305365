import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { BrowserRouter, useNavigate } from "react-router-dom";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_MIgnzwzcT",
    userPoolWebClientId: "70s3lvt32bndbu4saktip4h8qe",
    signInRedirectURI: window.location.href,
    signOutRedirectURI: window.location.href,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
