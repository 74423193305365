import React from "react";

const RationaleModal = ({ setIsOpen }) => {
  return (
    <div
      className="w-full h-screen fixed left-1/2 -translate-y-1/2 top-1/2 -translate-x-1/2 flex justify-center items-center z-50"
      style={{ zIndex: "99px" }}
    >
      <div className="w-[669px] p-8 py-8 bg-white shadow-md rounded-[16px] z-50 h-[750px] overflow-auto scroll-container">
        <div className="flex border-b border-mediumGray pb-8 justify-between z-50">
          <h4 className="text-2xl leading-[28px] font-semibold  ">Rationale</h4>
          <img
            src="./assets/tactic/closeIcon.png"
            alt="closeIcon"
            className="w-6 h-6 mt-1 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <h4 className="text-base font-semibold mt-8">
          Reference and data on file
        </h4>
        <ul
          style={{ listStyleType: "disc", paddingLeft: "20px" }}
          className="mt-4"
        >
          <li>
            <span className="font-normal text-base"> REF-53545:</span>
            <a href="#" className="underline text-secondaryColor ml-2">
              Loibl S et al J Clin Onocl (page 4 paragraph 7)
            </a>
          </li>
          <li>
            <span className="font-normal text-base">REF-50467:</span>
            <a href="#" className="underline text-secondaryColor ml-2">
              Mayer EL et al Lancet Oncol (page 1 paragraph 10)
            </a>
          </li>
        </ul>
        <h4 className="text-base font-semibold mt-8">
          Regulatory requirements
        </h4>
        <ul
          style={{ listStyleType: "disc", paddingLeft: "20px" }}
          className="mt-4"
        >
          <li>
            <a href="#" className="font-normal text-base text-secondaryColor">
              FDA label:
            </a>
            <span href="#" className="underline  ml-2">
              need to include “advanced” in product claims
            </span>
          </li>
          <li>
            <a href="#" className="font-normal text-base text-secondaryColor">
              SPC label:
            </a>
            <span href="#" className="underline  ml-2">
              need to include “advanced” in product claims
            </span>
          </li>
          <li>
            <a href="#" className="font-normal text-base text-secondaryColor">
              PMDA label:
            </a>
            <span href="#" className="underline  ml-2">
              need to include “advanced” in product claims
            </span>
          </li>
        </ul>
        <h4 className="text-base font-semibold mt-8">
          Regulatory requirements (local)
        </h4>
        <ul
          style={{ listStyleType: "disc", paddingLeft: "20px" }}
          className="mt-4"
        >
          <li>
            <span className="font-normal text-base">N/A</span>
          </li>
        </ul>

        <h4 className="text-base font-semibold mt-8">Market Research</h4>
        <ul
          style={{ listStyleType: "disc", paddingLeft: "20px" }}
          className="mt-4"
        >
          <li>
            <a
              href="#"
              className="font-normal text-base underline text-secondaryColor"
            >
              2022 Annual HCP Verzenio Brand Survey:
            </a>
            <span className="ml-2">
              HCPs respond better to emotional tone vs scientific tone or
              promotional tone
            </span>
          </li>
        </ul>

        <h4 className="text-base font-semibold mt-8">
          Historical claims performance
        </h4>
        <ul
          style={{ listStyleType: "disc", paddingLeft: "20px" }}
          className="mt-4"
        >
          <li>
            <a
              href="#"
              className="font-normal text-base underline text-secondaryColor"
            >
              Verzenio claim operational performance dashboard (all historical
              claim data):
            </a>
            <span className="ml-2">
              on average, leading with brand name produces higher engagement
            </span>
          </li>
        </ul>

        <h4 className="text-base font-semibold mt-8">Competitor claims</h4>
        <ul
          style={{ listStyleType: "disc", paddingLeft: "20px" }}
          className="mt-4"
        >
          <li>
            <a
              href="#"
              className="font-normal text-base underline text-secondaryColor"
            >
              Competitive Intelligence Profile - Ibrance (2 years data):
            </a>
            <span className="ml-2">
              also generally lead with brand name, indicating competitors
              message similarly 
            </span>
          </li>
          <li>
            <a
              href="#"
              className="font-normal text-base underline text-secondaryColor"
            >
              Competitive Intelligence Profile - Kisqali (2 years data):
            </a>
            <span className="ml-2">
              also generally lead with brand name, indicating competitors
              message similarly
            </span>
          </li>
        </ul>
      </div>
      <div className="bg-fontColor opacity-20 w-full h-screen absolute top-0 left-0"></div>
    </div>
  );
};

export default RationaleModal;
