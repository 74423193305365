import React, { useContext } from "react";
import { Context } from "../../../App";

const Filled = () => {
  const { setStage } = useContext(Context);
  return (
    <div className="w-[750px]">
      <p className="py-[20px] text-fontColor">Create Image</p>
      <div className="w-full border border-borderColor rounded-[16px] p-[32px]">
        <div className="mb-[128px] flex flex-col items-center">
          <img
            src="./assets/claim/message-question.svg"
            alt="message question"
          />
          <h3 className="text-[32px] font-bold mt-[15px]">Create an Image</h3>
        </div>
        <div className="">
          <h5 className="text-[20px]">Hello! Enter a prompt to get started.</h5>
        </div>
        <form className="flex flex-col mt-[48px]">
          <textarea
            type="text"
            placeholder="a photo of a beautiful black woman, with short hair, smiling wearing a sweater in a park in teal colorway"
            className="w-full rounded-[8px] bg-[#EFF0F2] px-[16px] py-[12px] h-[80px] resize-none text-[#4B5563] placeholder:text-[#4B5563]"
          />
          <button
            className="w-full rounded-[8px] bg-secondaryColor px-[16px] py-[12px] text-white mt-[80px]"
            onClick={() => setStage("Processing")}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Filled;
