import React from "react";
import Layout from "../components/layouts";
import CreateClaimComponents from "../components/pages/CreateClaimComponents";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
const CreateClaim = () => {
   const navigate = useNavigate();
  const { organization: currentOrganization } = useOrganization();
  const { user } = useUser();
  if (!user) {
    // Redirect to the sign-in page if orgRole is not available
    navigate("/sign-in");
    return null;
  }

  return (
    <Layout>
      {currentOrganization?.publicMetadata?.claim === "claim" ||
      user?.publicMetadata?.superAdmin ? (
        <CreateClaimComponents />
      ) : (
        <p className="flex justify-center items-center h-[88vh]">
          Not subscribed yet!
        </p>
      )}
    </Layout>
  );
};

export default CreateClaim;
