import React from "react";

const Icons = ({ data }) => {
  return (
    <button className=" w-[36px] h-[36px] rounded-[6px] flex justify-center items-center relative ml-8 ">
      <img src={data?.path} alt={data?.label} className="w-[24px]" />
      <div className="h-[10px] w-[10px] rounded-full bg-[#00ABC7] border-[2px] border-white absolute top-[5px] right-[4px]"></div>
    </button>
  );
};

export default Icons;
