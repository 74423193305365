import React, { useContext, useState } from "react";
import { Context } from "../../../App";

const data = ["Innovators", "Early Majority", "Late Majority", "Digital Only"];

const Filled = () => {
  const { setStage, setInputValue, inputValue } = useContext(Context);

  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    const isExist = checked.filter((item) => {
      return item === value;
    });
    if (isExist.length === 0) {
      setChecked([...checked, value]);
    } else {
      const d = checked.filter((item) => {
        return item !== value;
      });
      setChecked(d);
    }
  };

  const isChecked = (value) => {
    const d = checked.filter((item) => {
      return item === value;
    });
    if (d.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="w-[750px]">
      <p className="pt-[20px] pb-10 text-fontColor">Create a Tactic</p>
      <div className="w-full border border-borderColor rounded-[4px] p-[32px]">
        <div className="mb-[64px] flex flex-col items-center">
          <img
            src="./assets/claim/message-question.svg"
            alt="message question"
          />
          <h3 className="text-[32px] font-bold mt-[15px]">Create a Tactic</h3>
        </div>
        <div className="">
          <h5 className="text-base font-medium ">
            Hello! How can I help you today?
          </h5>
        </div>
        <form className="flex flex-col mt-[18px]">
          <input
            type="text"
            placeholder="Enter your prompt here ..."
            className="w-full rounded-[8px] bg-[#EFF0F2] px-[16px] py-[12px] focus:outline-none focus:outline-secondaryColor"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <div className="mt-[30px] grid grid-cols-1 gap-2 bg-lightSkyBlue p-4">
            <b className="text-fontColor">Example Prompts</b>
            <p
              className="text-secondaryColor cursor-pointer hover:text-secondaryColor"
              onClick={() =>
                setInputValue(
                  "Generate a set of Verzenio tactics based on latest efficacy data."
                )
              }
            >
              Generate a set of Verzenio tactics based on latest efficacy data.
            </p>
            <p
              className="text-secondaryColor cursor-pointer hover:text-secondaryColor"
              onClick={() => setInputValue("Generate a HQ email.")}
            >
              Generate a HQ email.
            </p>
          </div>

          <div className="w-full mt-[40px]">
            <p className="font-medium text-base">Select Segments</p>
            <div className="flex mt-3">
              {data.map((item, i) => (
                <button
                  key={i}
                  type="button"
                  className={`text-fontColor flex items-center border border-[#EFF0F2] rounded p-2 px-3 mr-4 ${
                    isChecked(item) ? "bg-lightGray" : ""
                  }`}
                  onClick={() => handleCheck(item)}
                >
                  <img
                    src={`./assets/tactic/${
                      isChecked(item) ? "CheckSquare" : "Square"
                    }.png`}
                    alt={isChecked(item) ? "checked" : "not checked"}
                  />
                  <span className="ml-2">{item}</span>
                </button>
              ))}
            </div>
          </div>

          <button
            className="w-full rounded-[4px] bg-primaryColor px-[16px] py-[12px] text-white mt-[48px]"
            onClick={() => setStage("GeneratingTactic")}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Filled;
