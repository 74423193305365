import React, { useContext, useEffect, useState } from "react";
import Filled from "./Filled";
import Processing from "./Processing";
import ChooseClaim from "./ChooseClaim";
import Review from "./Review";
import Success from "./Success";
import GlobalClaimTree from "./GlobalClaimTree";
import { Context } from "../../../App";
import { generateClaims, getGeneratedClaims } from "../../../utils/ajax-proxy"; 
import GlobalProcessing from "./GlobalProcessing";

const CreateClaimComponents = () => {
  const { stage, setStage, referenceDetails, setReferenceDetails, setData } = useContext(Context);
  const [intervalID, setInterValId] = useState();
  const [claimsResult, setClaimsResult] = useState(); 

  const returnComponents = () => {
    if (stage === "") {
      return <Filled />;
    } else if (stage === "Processing") {
      return <Processing />;
    } else if (stage === "ChooseClaim") {
      return <ChooseClaim />;
    } else if (stage === "Review") {
      return <Review />;
    } else if (stage === "TreeProcessing") {
      return < GlobalProcessing/>;
    } else if (stage === "GlobalTree") {
      return <GlobalClaimTree />;
    } else if (stage === "Success") {
      return <Success />;
    } else {
      setStage("");
    }
  };

  useEffect( () => {
    if (referenceDetails && referenceDetails !== "" ) {  
      const getData = async () => {
        const res = await generateClaims(referenceDetails);
        if (res?.status == 200 && res?.data?.id) {
          fetchClaims(res?.data?.id);
          setReferenceDetails("")
        }
      }
      getData()
    }
  }, [referenceDetails, setReferenceDetails ]);

  const fetchClaims = (id) => {
    let intervalID = setInterval(async () => {
      let claims_response = await getGeneratedClaims(id);
      if (claims_response?.data?.length > 0 && claims_response?.status == 200) {
        setClaimsResult(claims_response);
      }
    }, 5000);
    setInterValId(intervalID);
  };

  useEffect(() => {
    if (
      claimsResult &&
      claimsResult?.data?.length > 0 &&
      claimsResult.data[0]?.generated_claims?.length !== 0
    ) {
      stopFetchClaims();
      setData(claimsResult.data[0]?.generated_claims); 
      setClaimsResult(); 
      setStage("ChooseClaim");
    }
  }, [claimsResult]);

  const stopFetchClaims = () => {
    clearInterval(intervalID);
  };

  return (
    <div className="px-[32px] flex justify-center">{returnComponents()}</div>
  );
};

export default CreateClaimComponents;
