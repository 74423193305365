import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import { Context } from "../../../../App";

const ZoomImageModal = ({
  setIsZoomViewClick,
 
}) => {
  const contents1 = {
    title: "Key Claim Text (visible on tactic)",
    data: [
      {
        title: "Asset Title",
        text: "DRFS in patients with HR+, HER2-, node-positive EBC at high risk of recurrence (Cohort 1)²*",
      },
      {
        title: "Asset Footnote",
        text: "N/A",
      },
      {
        title: "Asset Long Description",
        text: "N/The results from the distant relapse-free survival (DRFS) analysis for patients in Cohort 1 in the monarchE trial are presented in a Kaplan-Meier curve. The reduction in risk of distant relapse was 35%. The hazard ratio was 0.65 with a 95% confidence interval of 0.56 to 0.76. Verzenio plus ET achieved a DRFS of 87.9% at 4 years whereas ET alone achieved 81.8%. The absolute improvement of DRFS was 2.8% at 2 years and 6.1% at 4 years vs ET alone.²",
      },
      {
        title: "Asset Alt. Tag",
        text: "Verzenio plus ET reduced the risk of distant relapse through 4 years",
      },
      {
        title: "Asset URL",
        text: "https://lilly-promomats.veevavault.com/ui/#doc_info/1446131/1/0?bi=1446130&bma=2&bmi=0&nk=1677251932197%3A-1233275271&pi=1446130&pma=2&pmi=0&idx=0&pt=bdr&sm=&tvsl=JnJlcG9ydFdhbGs9JnJJZDOwX2lkXzEwJnJLZXk9MFJQMDAwMDAwMESJMDAyJnJlaz0zMzkxLS1IMTc2OWUyZS1INjNkLTRhMDQtYmIxNC0zN2EwMzA4Njc4MTYmcHJvZHVjdF9fdiUyQyUyQyUyQOVRPTAwUDAwMDAwMDAwMEswMSZzaz1Eb2N1bWVudC5uYW1lJnNvPUFTQOVOREIORYZiaT0xNDQ2MTMwJmJtY TOyJmJtaTOw&anQS=page1&annotate=false",
      },
      {
        title: "Body Copy",
        text: "Reduction in risk of metastases continued beyond the 2-year Verzenio treatment period¹-2",
      },
    ],
  };


  return (
    <div className="w-full h-screen fixed left-1/2 -translate-y-1/2 top-1/2 -translate-x-1/2 flex justify-center items-center z-50 ">
      <div className="w-[939px] p-8 py-8 bg-white shadow-md rounded-[16px] z-50  h-[96vh]">
        <div className="flex border-b border-mediumGray pb-8 justify-between">
          <h4 className="text-2xl leading-[28px] font-semibold  ">
            Visual Representation Details
          </h4>

          <img
            src="./assets/tactic/closeIcon.png"
            alt="closeIcon"
            className="w-6 h-6 mt-1 cursor-pointer"
            onClick={() => {
              setIsZoomViewClick(false);
            }}
          />
        </div>
        <div className="h-[80vh] overflow-x-auto ">
          <img
            src="./assets/claim/zoomView.png"
            alt="zoomView"
            className="w-full"
          />
          <div className="grid grid-cols-1 gap-3 mt-4">
            <Card data={contents1} index={1} />
            <Card data={contents1} index={0} />
            <Card data={contents1} index={0} />
            <Card data={contents1} index={0} />
          </div>
        </div>
      </div>

      <div className="bg-fontColor opacity-20 w-full h-screen absolute top-0 left-0"></div>
    </div>
  );
};

export default ZoomImageModal;
