import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import { Context } from "../../../../App";
import RationaleModal from "./RationaleModal";
import ZoomImageModal from "./ZoomImageModal";

const contents3 = {
  title: "Additional Information (not visible on tactic)",
  data: [
    {
      title: "Use RDMF Override (Affiliate-level modules only)",
      text: "No",
    },
    {
      title: "Required Claims",
      text: "GM-35472 (monarchE Study Design) OR GM-36326 (target patient ID) GM-35471 (AL-HCP-PAY-EBC-IDFS-C1 OS IA2 through 4 years)",
    },
    {
      title: "Excluded Claims",
      text: "",
    },
    {
      title: "Instructions for Use (IFUs)",
      ul: [
        "Must only be used by countries that can reference the SmPC",
        "Must not be used in limited-space tactics (eg, banner ads and social media posts [eg, Facebook posts])",
        "Must not be used in a reminder ad",
        "Must not be used as an email subject line",
        "Required disclaimer material facts must be presented at body copy size",
        "Must be used following or in direct conjunction with the full Verzenio EBC Indication Statement (C-AL-GM-XXXX)",
        "Must be used following or in direct conjunction with the monarchE IDFS data (GM-32813)",
        `At the time of writing these claims, Verzenio was the only CDK4 & 6 inhibitor approved for adjuvant treatment. As long as no other CDK4 & 6 inhibitor reports positive DRFS data, the statement including "only" may be used and must reference competitor publications and disclosures on their EBC trials (PALLAS publication, NATALEE study detail)`,
        "At the time of writing, the Verzenios Summary of Product Characteristics presents 24-month DRFS data for Cohort 1 in a table; it does not currently feature a DRFS KM chart nor 42-month data points. Care should be taken to avoid using claims relating to a time point beyond 24 months and to avoid use of the DRFS KM chart unless such claims and representations are approved for use in your region",
        "The KM chart as it appears here must be used in conjunction with claims and bullets in this document",
        "Not all body copy must be used together, please consult with your local review team for guidance",
      ],
      text: "[Annotations: Verzenios SmPC 2022: p3/Sec4.1/para1,2; p12/Sec5.1/para2/ln1-2; p13/Sec5.1/para4; p14/Sec5.1/Table9 Johnston 2022/p2/col2/para2/ln19,20; p3/col1/para1; p6/col2/para2; p8/col1/para1/col2/para1/ln1; appendix/p19/FigA3/b Mayer 2021/p217/col1/para3/ln18-21; para4/col2/para1/ln1-18; p218/col2/para2/ln1-5; NATALEE_clinicalttrials.gov/p2/study description/p3/completiondate Loibl 2021 p1519/col1/para3; p1525/col2/para2,3",
    },
    {
      title: "Safety Information Instructions",
      text: "",
    },
    {
      title: "Advisory Comment Annotation Information (Internal Instruction)",
      text: "",
    },
    {
      title: "Regulatory Feedback",
      text: "",
    },
  ],
};

const contents4 = {
  title: "Internal Metadata (not visible on tactic)",
  data: [
    {
      title: "Title",
      text: "GCCM-12345-AL-EBC--OS IA2- DRFS",
    },
    {
      title: "Description",
      text: "",
    },
    {
      title: "Keywords",
      text: "",
    },
    {
      title: "Content Category",
      text: "Efficacy",
    },
    {
      title: "Content Subcategory",
      text: "MonarchE",
    },
    {
      title: "Intended Segment",
      text: "",
    },
    {
      title: "Intended Subsegment",
      text: "",
    },
    {
      title: "Reference Study Name",
      text: "",
    },
    {
      title: "Tonality",
      text: "",
    },
    {
      title: "Intended Channel",
      text: "",
    },
  ],
};


const Review = () => {
  const { setStage, data, selected, reference_dictionary } = useContext(Context);
  const [theData, setTheData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const[isZoomViewClick,setIsZoomViewClick]=useState(false)
  const [references, setReferences] = useState([]);

  const getLabelByReference = (reference) => reference_dictionary.find((item) => item.PubID == reference);

  const contents1 = {
    title: "Key Claim Text (visible on tactic)",
    data: [
      {
        title: "Headline",
        text: theData?.modeloutput.English.heading,
      },
      {
        title: "Subheadline",
        text: theData?.modeloutput.English.subheading,
      },
      {
        title: "Eyebrow",
        text: theData?.modeloutput.English.eyebrow,
      },
      {
        title: "Visual Representation",
        image: "./assets/zoomView.png",
      },
      {
        title: "Body Copy",
        text: theData?.modeloutput.English.body,
      },
    ],
  };

  
  useEffect(() => {
    if(!data) return;
    setTheData(data[selected]);
    if (data[selected]?.references) {
      setReferences(JSON.parse(data[selected]?.references));
    }
  }, [data, selected]);

  const contents2 = {
    title: "Additional Information (visible on tactic)",
    data: [
      {
        title: "Required Disclaimers and/or Material Facts",
        text: "Efficacy analyses in Cohort 1 were not alpha controlled for statistical significance testing Absolute difference was calculated by subtraction of the DRFS rates between the two arms at each year. Results depicted in the KM curve should not be interpreted beyond 48 months.",
      },
      {
        title: "RDMF Override (Affiliate-level modules only)",
        text: "",
      },
      {
        title: "Abbreviations Footnotes ",
        text: "CDK4 & 6=cyclin-dependent kinases 4 and 6; CI=confidence interval; DRFS=distant relapse-free survival; EBC=early breast cancer; ET=endocrine therapy; HER2–=human epidermal growth factor receptor 2–negative; HR=hazard ratio; HR+=hormone receptor–positive; IDFS=invasive disease-free survival; ITT=intent-to-treat *High-risk EBC in Cohort 1 was defined as patients who had 4+ positive nodes or 1-3 positive nodes and tumors that were ≥5 cm, histological Grade 3, or both.¹",
      },
      {
        title: "References (Multiple links may be included)",
        // text: "[placeholder for cluster-specific Verzenio label]",
        text: "",
        ul: references.map((ref) => getLabelByReference(ref)?.Abbreviation)
         
      },
    ],
  };

  

  return (
    <div className="w-[750px]">
      {isOpen && <RationaleModal setIsOpen={setIsOpen} />}
      {isZoomViewClick && (
        <ZoomImageModal setIsZoomViewClick={setIsZoomViewClick} />
      )}

      <div className="flex items-center py-[20px]">
        <p className="text-fontColor font-semibold">Create a Claim</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor font-semibold">Choose Claim</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor">Review</p>
      </div>
      <div className="w-full mt-8">
        <div className="flex rounded-[8px] items-start border border-borderColor">
          <div className="w-[150px] p-5 text-fontColor">Claim Headline</div>
          <div className="flex-1 p-5  group-hover:text-white pr-[20px] font-semibold">
            {theData?.modeloutput.English.heading}
            <div className=" flex  gap-5 items-center justify-end mt-4 font-medium">
              <button
                className="flex  items-center h-[30px] text-base  p-3  font-normal w-[128px] text-secondaryColor border border-secondaryColor rounded-md gap-2 justify-center"
                onClick={() => setIsOpen(true)}
              >
                <img src="./assets/tactic/Sparkle.png" alt="closeIcon" />
                Rationale
              </button>
              <button className="border border-secondaryColor text-secondaryColor  flex items-center rounded-[6px] text-[14px] p-1 px-2 h-[30px]">
                <img
                  src="./assets/ShareIcon.png"
                  alt="Edit"
                  className="mr-1 w-4 h-4"
                />
                share
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-3 mt-4">
          <Card
            data={contents1}
            setIsZoomViewClick={setIsZoomViewClick}
            isZoomViewClick={isZoomViewClick}
            index={0}
          />
          <Card data={contents2} index={0} />
          <Card data={contents3} index={0} />
          <Card data={contents4} index={0} />
        </div>
      </div>

      <div className="flex justify-end mt-[80px] mb-[32px]">
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8"
          onClick={() => setStage("ChooseClaim")}
        >
          Back
        </button>
        <button
          className="bg-primaryColor text-white rounded-[4px] p-3 px-8 ml-5"
          onClick={() => setStage("TreeProcessing")}
        >
          Create Global Tree
        </button>
      </div>
    </div>
  );
};

export default Review;
