import React, { useContext } from "react";
import { Context } from "../../../App";

const Success = () => {
  const { setStage } = useContext(Context);

  return (
    <div className="w-[750px]">
      <div className="w-full border border-borderColor rounded-[16px] p-[32px] h-[450px] mt-5 flex flex-col justify-center items-center mt-20">
        <div className="">
          <img src="./assets/success.png" alt="success" />
        </div>
        <h4 className="text-[24px] mt-[35px] font-bold">Success</h4>
        <p className="text-fontColor mt-[15px]">Successfully shared.</p>

        <div className="mt-20">
          <button
            className="border border-borderColor rounded-[8px] p-3 px-8 pl-6 flex items-center"
            onClick={() => setStage("")}
          >
            <img src="./assets/CaretLeft.png" alt="Caret Left" />
            <span className="ml-1">Back to Home</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
