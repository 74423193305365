import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../App";
import { getImage } from '../../../../utils/ajax-proxy'

const CommunityCard = ({ data, localize, setIsRationaleClick, claim_data, selected, reference_dictionary, setSavedImage, selectedCard, savedImage }) => {
  const [toggler, setToggler] = useState(false);
  const { setSelectedCard } = useContext(Context);
  const [tacticImage, setImage] = useState("");
  const [referenceids, setReferenceIds] = useState(['REF-54984', 'REF-49941',  'REF-46154', 'REF-40577', 'REF-37579']);
  const [theData, setTheData] = useState(null);

  useEffect(() => {
    if(selectedCard === "patient") return
    if (localize !== "") {
      setToggler(false);
      fetchImage( localize, 1)
    } else {
      fetchImage(localize == "" ? 'us' : localize, 1)
    } 
  }, []);

  useEffect(() => {
    if(!claim_data) return;
    setTheData(claim_data[selected]);
    if (claim_data[selected]?.references) {
      setReferenceIds(JSON.parse(claim_data[selected]?.references));
    }
  }, [claim_data, selected]);

  const fetchImage = async(localize, segment) => {
    let response = await getImage(localize, segment); 
    if(response?.status == 200) {
      setImage(response?.data?.image)
      setSavedImage(response?.data?.image)
    }  else {
      setImage("") 
      setSavedImage("")
    }
  }

  const togglerHandler = (value) => { 
    fetchImage(localize == "" ? 'us' : localize, !value ? 1 : 2)
  }


  const getLabelByReference = (reference) => reference_dictionary.find((item) => item.PubID == reference);

  return (
    <div className=" overflow-hidden">
      <p className="text-base font-normal  mb-2 text-fontColor">Subsegment</p>
      <div className="bg-[#E5E7EB] flex rounded-[8px] items-center">
        <div className="flex bg-white w-full gap-4">
          <div
            className={`border border-borderColor rounded-[8px] p-2 px-8 pl-6 flex items-center cursor-pointer gap-6 ${
              !toggler && "bg-lightGreen"
            }`}
            onClick={() => {
              setToggler(false);
              setSelectedCard("community");
              togglerHandler(false)
            }}
          >
            <img
              src="./assets/tactic/communityLogo.png"
              alt="communityLogo"
              className="w-[30px] h-[20px]"
            />
            <p className="text-base font-normal">
              Community Practitioners (Global)
            </p>
          </div>
          <div
            className={`border border-borderColor rounded-[8px] p-2 px-8 pl-6 flex items-center cursor-pointer gap-6 ${
              toggler && "bg-lightGreen"
            }`}
            onClick={() => {
              setToggler(true);
              setSelectedCard("patient");
              togglerHandler(true)
            }}
          >
            <img
              src="./assets/tactic/communityLogo.png"
              alt="communityLogo"
              className="w-[30px] h-[20px]"
            />
            <p className="text-base font-normal">Patient Advocates (Global)</p>
          </div>
        </div>
      </div>
      <div className="flex gap-12">
        <p className="text-base mt-4 mb-[14px] font-normal text-fontColor">
          MLR Confidence
          <span className="text-base font-semibold text-green  pl-2 underline">
            95%
          </span>
        </p>
        <button
          className="flex  items-center h-[30px] text-base  p-3  mt-4 w-[128px] text-secondaryColor border border-secondaryColor rounded-md gap-2 justify-center"
          onClick={() => {
            setIsRationaleClick(true);
          }}
        >
          <img src="./assets/tactic/Sparkle.png" alt="closeIcon" />
          Rationale
        </button>
      </div>

      <div className="bg-lightGray">
        <div className="p-12">
          <div className="bg-white py-5 px-2">
            <div className="flex gap-3 items-center">
              <p className="text-lg font-medium">To:</p>
              <p className="text-lg">
                {"<Physician@gmail.com(one email address only)>"}
              </p>
            </div>
            <div className="flex gap-3 items-center">
              <p className="text-lg font-medium">From options:</p>
              <p className="text-lg">Lilly@lilly.gmail</p>
            </div>
            <div className="flex gap-3 items-center mt-10">
              <p className="text-lg font-medium">Subject Line:</p>
              <p className="text-lg">
                See the latest efficacy data for Verzenio
              </p>
            </div>
            <div className="flex gap-3 items-center mt-10">
              <p className="text-lg font-medium">Preheader:</p>
              <p className="text-lg">Do you have patients at high risk ?</p>
            </div>
          </div>
          <div className="flex justify-center items-center text-lg font-medium mt-10">
            To view this email in browser,
            <a href="#" className="text-primaryColor text-lg ml-1 underline">
              click here.
            </a>
          </div>
        </div>
        <div className=" mx-12 bg-white">
          <img src="./assets/tactic/Tactic.png" alt="" />
        </div>
        <div className="mx-12 bg-white mt-5 p-5">
          {/* <img src="./assets/tactic/tacticImage.png" alt="" /> */}
          <img src={`data:image/png;base64,${savedImage}`} alt="" />
          <h1 className="text-4xl text font-bold text-darkBlue mt-5">
            Reduction in risk of distant recurrence continued beyond the 2-year
            Verzenio treatment period1
          </h1>
          <h4 className="text-2xl text font-medium text-darkGreen pt-3">
            Verzenio + ET: The first and only CDK 4/6 inhibitor approved to
            reduce risk of metastatic disease through 4 years1-6
          </h4>
          <p className="text-2xl text font-medium text-darkGreen pt-3">
            Distant Relapse Free Survival at 4-years1 32
          </p>
        </div>
        <div className="mx-12 bg-white p-5">
          <img src="./assets/tactic/ZoomView.png" alt="zoomView" />
          <p className="font-medium text-lg">
            Efficacy analyses in were not alpha controlled for statistical
            significance testing
          </p>
          <p className="font-medium text-lg mt-7">
            Statistical significance was met in the ITT population at the
            pre-planned interim analysis with 15.5 months median follow-up2,3
          </p>
          <p className="font-medium text-lg mt-7">
            Absolute difference was calculated by subtraction of the DRFS rates
            between the two arms at each year. Results depicted in the KM curve
            should not be interpreted beyond 48 months.
          </p>
        </div>
        <div className="mx-12 bg-white mt-5 p-5">
          <h1 className="text-xl p-5 text-center text-darkBlue font-bold">
            Find out how Verzenio could help reduce the risk of distant
            recurrence in your patients.
          </h1>
          <div className="flex justify-center">
            <button className="w-[200px] rounded-[8px] bg-darkBlue px-[16px] py-[12px] text-white  font-bold ">
              Learn More
            </button>
          </div>
        </div>

        <div className="mx-12 bg-white mt-5 p-5">
          <h4 className="font-medium text-xl">References:</h4>
           { referenceids.map((ref) => getLabelByReference(ref)?.Abbreviation)?.map((item, index) => (<p>{index+1}.{item}</p>))}
        </div>
        <div className="mx-12">
          <p className="text-lg font-normal p-5">COLORADO PRESCRIBER?</p>
          <p className="text-lg font-medium  px-5">
            Please see
            <span className="text-darkGreen underline ml-1">
              Required Price Disclosures
            </span>
          </p>
          <p className="mt-4 font-normal text-lg px-5">VERMONT PRESCRIBER?</p>
          <p className="mt-4 font-medium text-lg px-5">
            <span className="text-darkGreen underline ">
              Please see Required Price Disclosures
            </span>{" "}
            for Pill-Based Products.
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            Verzenio® is a registered trademark owned or licensed by Eli Lilly
            and Company, its subsidiaries, or affiliates.
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            Do not reply to this email. If you have a question about a Lilly
            product, please call 1-800-LillyRx (1-800-545-5979).
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            To unsubscribe from marketing email communications from Lilly USA,
            LLC,{" "}
            <span className="text-darkGreen font-semibold underline pl-1">
              <a href="#">click here.</a>
            </span>{" "}
            or call 1-800-LillyRx (1-800-545-5979).
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            For more information about Lilly's Privacy Practices,
            <span className="text-darkGreen font-semibold underline pl-1">
              <a href="#">click here.</a>
            </span>
          </p>
          <p className="mt-4 font-medium px-5">Lilly USA, LLC</p>
          <p className="mt-4 font-medium px-5">
            Lilly Corporate Center, DC 5228
          </p>
          <p className="mt-4 font-medium px-5">Indianapolis, IN 46285</p>
          <p className="mt-4 font-medium px-5">
            Attn: Email Processing Department
          </p>
          <p className="mt-4 font-medium px-5">
            © Lilly USA, LLC 2023. All rights reserved.
          </p>
          <p className="mt-4 font-medium px-5">PP-AL-US-XXXX 07/2023</p>
        </div>
        <div className="mx-12 flex justify-end mt-4 pb-7">
          <img src="./assets/Logo.png" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
