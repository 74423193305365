import React, { useContext } from "react";
import { Context } from "../../../App";


const ConfirmModal = ({ confirmModal, setConfirmModal }) => {
  const { setStage} = useContext(Context);

  return (
    <div className="w-full h-screen fixed left-1/2 -translate-y-1/2 top-1/2 -translate-x-1/2 flex justify-center items-center z-50">
      <div className="w-[380px] p-8  bg-white shadow-md rounded-[16px] z-50  ">
        <div className="flex justify-between">
          <h4 className="text-xl leading-[28px] font-medium w-[316px] text-center  ">
            Are you ready to share with the Global Marketing Lead?
          </h4>
        </div>
        <div className="flex gap-7 mt-8">
          <button
            className="border border-borderColor rounded-[4px] p-3 px-8 w-[150px]"
            onClick={() => setConfirmModal(!confirmModal)}
          >
            Cancel
          </button>
          <button
            className="bg-primaryColor text-white rounded-[4px] p-3 px-8  w-[150px]"
            onClick={() => setStage("Success")}
          >
            Confirm
          </button>
        </div>
      </div>
      <div className="bg-fontColor opacity-20 w-full h-screen absolute top-0 left-0"></div>
    </div>
  );
};

export default ConfirmModal;
