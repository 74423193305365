import { useForm } from "react-hook-form";
import { useOrganization } from "@clerk/clerk-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function MyAddUserModal({
  isModalOpen,
  setIsModalOpen,
  organizationId,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { organization } = useOrganization();
  const { invitationList } = useOrganization({ invitationList: {} });

  if (!invitationList) {
    return null;
  }

  const revoke = async (inv) => {
    await inv.revoke();
  };

  const onSubmit = async (data) => {
    const { email, role } = data;
     await organization
      .inviteMember({
        emailAddress: email,
        role,
      })
      .then((res) => {
        if (res) {
          toast.success("Invite sent");
        }
      })
      .catch((err) => toast.error(err.errors[0].message));
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === "modal") {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div
          id="modal"
          onClick={handleOverlayClick}
          className="modal-overlay absolute w-full h-full bg-black opacity-50"
        ></div>
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold text-black">Add user</p>
              <button className="modal-close cursor-pointer z-50">
                <svg
                  className="fill-current text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M1 1L17 17M17 1L1 17" />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                  className="form-input border-lightGreen w-full px-4 py-3 border rounded-lg focus:outline-none focus:shadow-outline"
                  placeholder="xyz@gmail.com"
                />
                {errors.email && (
                  <span className="text-error font-semibold text-xs">
                    Email is required
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="role"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  {...register("role", { required: true })}
                  className="form-select border-lightGreen w-full px-4 py-3 rounded-lg border focus:outline-none focus:shadow-outline"
                >
                  <option value="admin">ADMIN</option>
                  <option value="basic_member">MEMBER</option>
                </select>
                {errors.role && <span>Role is required</span>}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-primaryColor hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="bg-primaryColor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save changes
                </button>
              </div>
            </form>
            {invitationList.length > 0 && (
              <div>
                <h2 className="text-error">Pending invitations</h2>
                <ul>
                  {invitationList.map((i) => (
                    <li key={i.id} className="flex justify-between mt-1">
                      <div className="font-semibold">{i.emailAddress}</div>
                      <button
                        className="bg-primaryColor rounded-sm text-sm text-white px-2"
                        onClick={() => revoke(i)}
                      >
                        Revoke
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
