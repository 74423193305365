import React from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center  h-screen z-50 bg-lightBlack absolute w-screen left-0 top-0 ">
      <div className="animate-spin rounded-full border-t-4 border-blue-500 border-opacity-50 h-12 w-12"></div>
    </div>
  );
};

export default Loader;
