import React, { useContext } from "react";
import { Context } from "../../../App";

const Filled = () => {
  const { handleSubmit, inputValue, setInputValue } = useContext(Context);

  return (
    <div className="w-[750px]">
      <p className="pt-[20px] pb-10 text-fontColor">Create a Claim</p>
      <div className="w-full border border-borderColor rounded-[4px] p-[32px]">
        <div className="mb-[64px] flex flex-col items-center">
          
          <img
            src="./assets/claim/message-question.svg"
            alt="message question"
          />
          <h3 className="text-[32px] font-bold mt-[15px]">Create a Claim</h3>
        </div>
        <div className="">
          <h5 className="text-base font-medium">
            Hello! How can I help you today?
          </h5>
        </div>
        <form
          className="flex flex-col mt-[18px]"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            type="text"
            placeholder="Enter your prompt here:"
            className="w-full rounded-[8px] bg-[#EFF0F2] px-[16px] py-[12px] focus:outline-secondaryColor"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />

          <div className="mt-[30px] grid grid-cols-1 gap-2 bg-lightSkyBlue p-4">
            <b className="text-fontColor">Example Prompts</b>
            <p
              className="text-secondaryColor cursor-pointer hover:text-secondaryColor"
              onClick={() =>
                setInputValue(
                  "Generate global claim options based on EBC: Unmet need, post 2-year treatment."
                )
              }
            >
              Generate global claim options based on EBC: Unmet need, post
              2-year treatment.
            </p>
            <p
              className="text-secondaryColor cursor-pointer hover:text-secondaryColor"
              onClick={() =>
                setInputValue(
                  "Generate a set of claims based on the latest data."
                )
              }
            >
              Generate a set of claims based on the latest data.
            </p>
          </div>
          <button className="w-full rounded-[4px] bg-primaryColor px-[16px] py-[12px] text-white mt-[48px]">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Filled;
