import React, { useContext, useState } from "react";
import Action from "../../common/Action";
import { Context } from "../../../App";
import ViewRationaleModal from "./ViewRationaleModal";

const ChooseClaim = () => {
  const [option, setOption] = useState(null);
  const [isViewRationaleClick, setIsViewRationaleClick] = useState(false);

  const { setStage, data, selected, setSelected } = useContext(Context);


  return (
    <div className="w-[750px]">
      {isViewRationaleClick && (
        <ViewRationaleModal
          setIsViewRationaleClick={setIsViewRationaleClick}
          setSelected={setSelected}
        />
      )}
      <div className="flex items-center py-[20px]">
        <p className="text-fontColor font-semibold">Create a Claim</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor">Choose Claim</p>
      </div>
      <div className="w-full border border-borderColor rounded-[16px] p-[32px]">
        <h4 className="text-[24px] font-bold">Select a claim to continue</h4>

        <p className="text-fontColor mt-[48px]">
          {data.length} possible options to consider:
        </p>
        <div className="grid grid-cols-1 gap-3">
          <div className="bg-[#F5F6F7] flex rounded-[8px] mt-4 items-center">
            <div className="flex-1 p-3 text-fontColor pl-[100px]">
              Claim Headline
            </div>
            <div className=" p-2">
              <button className="border border-secondaryColor text-secondaryColor w-full flex items-center rounded-[6px] text-[14px] p-1 px-2">
                <img
                  src="./assets/ShareIcon.png"
                  alt="Edit"
                  className="mr-1 w-4 h-4"
                />
                share
              </button>
            </div>
          </div>

          {data.map((item, i) => (
            <div
              className={`flex rounded-[8px] items-center  ${
                selected === i
                  ? "bg-primaryColor border-none"
                  : "border border-borderColor"
              }`}
              key={i}
            >
              <div
                className="w-[90px] p-3 text-fontColor cursor-pointer"
                onClick={() => setSelected(i)}
              >
                <div
                  className={`w-[18px] h-[18px] rounded-full border  ${
                    selected === i ? "border-white" : "border-[#9CA3AF]"
                  } flex justify-center items-center`}
                >
                  <div
                    className={`w-[12px] h-[12px] rounded-full bg-white ${
                      selected === i ? "block" : "hidden"
                    }`}
                  ></div>
                </div>
              </div>
              <div
                className={`flex-1 p-3 cursor-pointer ${
                  selected === i ? "text-white" : "text-fontColor"
                }`}
                onClick={() => setSelected(i)}
              >
                {item?.modeloutput.English.heading}
              </div>
              <div className="w-[50px] p-2 relative">
                {option === i && (
                  <div
                    className="fixed top-0 left-0 w-full h-[100vh]"
                    onClick={() => setOption(null)}
                  ></div>
                )}
                <button
                  className="w-full flex items-center rounded-[6px] text-[14px] p-1 px-2"
                  onClick={() => setOption(i)}
                >
                  {selected === i ? (
                    <img
                      src="./assets/DotsThreeAlt.png"
                      alt="Edit"
                      className="mr-1"
                    />
                  ) : (
                    <img
                      src="./assets/DotsThree.png"
                      alt="Edit"
                      className={`mr-1`}
                    />
                  )}
                </button>
                <Action
                  option={option}
                  index={i}
                  setIsViewRationaleClick={setIsViewRationaleClick}
                  setOption={setOption}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end mt-[80px] mb-[32px]">
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8"
          onClick={() => setStage("")}
        >
          Back
        </button>
        {(selected || selected===0) && (
          <button
            className="bg-primaryColor text-white rounded-[4px] p-3 px-8 ml-5"
            onClick={() => setStage("Review")}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default ChooseClaim;
