import axios from "axios";

const GET_GENERATED_CLAIMS =  "https://gv2qts1gi5.execute-api.us-east-1.amazonaws.com/v1/api/get_generated_claims";
const GENERATE_CLAIMS =  "https://gv2qts1gi5.execute-api.us-east-1.amazonaws.com/v1/api/generate_claims_async";
const GET_IMAGE = "https://gv2qts1gi5.execute-api.us-east-1.amazonaws.com/v1/api/get_image"

let axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: "Bearer pwcauthtokentest",
  }
};

 export const generateClaims = async (reference_value) => {
  try {
    const data = {
      "input_data": reference_value 
    };
    const response = await axios.post(GENERATE_CLAIMS, data, axiosConfig );
    return response
  } catch(e) {
    console.log('e', e)
  }
 }

export const getGeneratedClaims = async (reference_id) => {
  try {
    const data = { 
      "id": reference_id
    }; 
    const response = await axios.post(GET_GENERATED_CLAIMS, data, axiosConfig);
    return response
  } catch(e) {
    console.log('e', e)
  } 
}
 
export const getImage = async (country, subsegment) => {
  try {
    const data = {
      country,
      subsegment
    } 
    const response = await axios.post(GET_IMAGE, data, axiosConfig);
    return response
  } catch(e) {
    console.log('e', e)
  } 
}
 