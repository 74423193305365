import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";

const Table = ({ data, headings, setIsEditUserClicked, setUserData }) => {
  const [tableRows, setTableRows] = useState();
  const [tableHeadings, setTableHeeadings] = useState();


  useEffect(() => {
    setTableRows(data);
    setTableHeeadings(headings);
  }, [data, headings]);
  const handleDestroyClick = async (item) => {
    const response = await item
      ?.destroy()
      .then((res) => {
        if (res) {
          toast.success("Destroyed");
        }
      })
      .catch((err) => toast.error(err.errors[0].message));
  };

 

  return (
    <div className="w-full h-[90%]">
      <ToastContainer />
      <div className="w-full xl:w-full mb-12 xl:mb-0 mx-auto mt-6 h-[100%]">
        <div className=" flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-2xl h-[100%] ">
          <div className="block w-full overflow-x-auto  border border-borderColor rounded-[4px] mt-10">
            <table className="items-center bg-transparent w-full border-collapse">
              <thead>
                <tr className="border border-solid border-[#E4E4E4] border-t-0 border-l-0 border-r-0">
                  {tableHeadings &&
                    tableHeadings.map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="px-6 bg-blue text-blue align-middle text-base  py-3  border-t-0 border-l-0 border-r-0 font-bold whitespace-nowrap text-left"
                        >
                          {item}
                        </th>
                      );
                    })}
                </tr>
              </thead>

              <tbody>
                {tableRows &&
                  tableRows.map((item, index) => {
                    return (
                      <>
                        <tr key={item.id}>
                          <td className="border-t-0 font-medium px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 ">
                            {item.publicUserData.firstName || "-"}
                          </td>
                          <td className="border-t-0 px-6 align-middle font-medium border-l-0 border-r-0 text-sm whitespace-nowrap p-4 ">
                            {item.publicUserData.identifier || "-"}
                          </td>
                          <td className="border-t-0 px-6 align-middle  font-medium border-l-0 border-r-0 text-sm whitespace-nowrap p-4 ">
                            {item.role}
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer">
                            <AiFillEdit
                              color="black"
                              size={18}
                              onClick={() => {
                                setIsEditUserClicked(true);
                                setUserData({
                                  firstName: item.publicUserData.firstName,
                                  lastName: item.publicUserData.lastName,
                                  userId: item.publicUserData.userId,
                                });
                              }}
                            />
                          </td>
                          <td
                            className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer"
                            onClick={() => handleDestroyClick(item)}
                          >
                            <AiFillDelete color="red" size={18} />
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
