import React from "react";
import Layout from "../components/layouts";
import CreateImageComoponents from "../components/pages/CreateImageComponents";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

const CreateImage = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    if (!user) {
      // Redirect to the sign-in page if orgRole is not available
      navigate("/sign-in");
      return null;
    }

  return (
    <Layout>
      <CreateImageComoponents />
    </Layout>
  );
};

export default CreateImage;
