import React from "react";
import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";

const EditUserModal = ({ setIsEditUserClicked, userData, setEditStatus,editStatus }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userData ? userData.firstName : "", // Set to empty string if orgData is not provided
      lastName: userData ? userData.lastName : "",
    },
  });

  const onSubmit = async (data) => {
    const requestBody = {
      first_name: data.firstName,
      last_name: data.lastName,
      userId: userData.userId,
    };
     await fetch(
      `${process.env.REACT_APP_SERVER_URL}editUser`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    setEditStatus(!editStatus);
    setIsEditUserClicked(false);
  };

  return (
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div
        id="modal"
        className="modal-overlay absolute w-full h-full bg-black opacity-50"
      ></div>
      <div
        className={`modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-md shadow-lg z-50 
          `}
      >
        <div className=" flex justify-between items-center pr-5 pt-2">
          <h1 className="px-6 mt-2 font-bold text-primaryColor">Edit User</h1>
          <div
            className="cursor-pointer"
            onClick={() => setIsEditUserClicked(false)}
          >
            <RxCross2 />
          </div>
        </div>
        <form
          className="modal-content py-4 text-left px-6 h-1/6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-600"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <span className="text-error font-semibold text-xs">
                firstName is required
              </span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-600"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <span className="text-error font-semibold text-xs">
                lastName is required
              </span>
            )}
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-secondaryColor text-white rounded-md hover:bg-blue-600 focus:outline-none mt-4"
          >
            Edit User
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
