import React, { useContext, useEffect } from "react";
import Menu from "./Menu";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useState } from "react";
import { Context } from "../../../App";

const routeMenu = [
  {
    label: "Home",
    href: "/home",
    path1: "./assets/menu-icons/Home.png",
    path2: "./assets/menu-icons/HomeAlt.png",
  },
  {
    label: "Create a Claim",
    href: "/create_claim",
    path1: "./assets/menu-icons/Claim.png",
    path2: "./assets/menu-icons/ClaimAlt.png",
  },

  {
    label: "Create a Tactic",
    href: "/create_tactic",
    path1: "./assets/menu-icons/Tactic.png",
    path2: "./assets/menu-icons/TacticAlt.png",
  },
  {
    label: "Claim Explorer",
    href: "/claim_explorer",
    path1: "./assets/menu-icons/Explore.png",
    path2: "./assets/menu-icons/ExploreAlt.png",
  },
  {
    label: "Organization",
    href: "/create_organization",
    path1: "./assets/menu-icons/Explore.png",
    path2: "./assets/menu-icons/ExploreAlt.png",
  },
  {
    label: "Add User",
    href: "/add_user",
    path1: "./assets/menu-icons/Explore.png",
    path2: "./assets/menu-icons/ExploreAlt.png",
  },
];

const settingsMenu = [
  {
    label: "Supports",
    href: "/support",
    path: "./assets/menu-icons/Lifebuoy.png",
  },
  {
    label: "Settings",
    href: "/setting",
    path: "./assets/menu-icons/Gear.png",
  },
];

const themeMenu = [
  {
    label: "Dark Theme",
    href: "/dark-theme",
    path: "./assets/menu-icons/MoonStars.png",
  },
];

// const filterMenu=
const Sidebar = () => {
  const [filterMenu, setFilterMenu] = useState(false);
  const [orgCurrentImage, setOrgCurrentImage] = useState();
  const { user } = useUser();
  const { orgRole } = useAuth();

  const {
    setCurrentLogo,
    hasOrganizationLoaded,
    setHasOrganizationLoaded,
    currentLogo,
    organizationListData,
  } = useContext(Context);

  const { organization: currentOrganization } = useOrganization();

  useEffect(() => {
    if (user?.publicMetadata?.superAdmin) {
      setFilterMenu(true);
    }
  }, [user]);

  useEffect(() => {
    if (organizationListData?.length === 1 || !currentOrganization?.logoUrl) {
      return;
    }
    if (currentOrganization && hasOrganizationLoaded) {
      const timeoutId = setTimeout(() => {
        setCurrentLogo(currentOrganization.imageUrl);
        setHasOrganizationLoaded(false);
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [currentOrganization, hasOrganizationLoaded]);


  const filteredRouteMenu = filterMenu
    ? routeMenu // Include all menu items if there are organizations
    : routeMenu.filter((menuItem) => {
        if (!currentOrganization) {
          return (
            menuItem.label !== "Create a Tactic" &&
            menuItem.label !== "Organization" &&
            menuItem.label !== "Create a Claim" &&
            menuItem.label !== "Add User"
          );
        } else if (
          currentOrganization?.publicMetadata?.claim &&
          currentOrganization?.publicMetadata?.tactic &&
          orgRole !== "basic_member"
        ) {
          return menuItem.label !== "Organization";
        } else if (
          currentOrganization?.publicMetadata?.claim &&
          currentOrganization?.publicMetadata?.tactic &&
          orgRole === "basic_member"
        ) {
          return (
            menuItem.label !== "Organization" && menuItem.label !== "Add User"
          );
        } else if (!currentOrganization) {
          return (
            menuItem.label !== "Create a Tactic" &&
            menuItem.label !== "Organization" &&
            menuItem.lable !== "Create a Claim" &&
            menuItem.lable !== "Add User"
          );
        } else if (
          currentOrganization?.publicMetadata?.claim &&
          orgRole !== "basic_member"
        ) {
          return (
            menuItem.label !== "Create a Tactic" &&
            menuItem.label !== "Organization"
          );
        } else if (
          currentOrganization?.publicMetadata?.claim &&
          orgRole === "basic_member"
        ) {
          return (
            menuItem.label !== "Create a Tactic" &&
            menuItem.label !== "Organization" &&
            menuItem.label !== "Add User"
          );
        } else if (
          currentOrganization?.publicMetadata?.tactic &&
          orgRole === "basic_member"
        ) {
          return (
            menuItem.label !== "Create a Claim" &&
            menuItem.label !== "Organization" &&
            menuItem.label !== "Add User"
          );
        } else {
          return (
            menuItem.label !== "Organization" &&
            menuItem.label !== "Create a Claim"
          );
        }
      });

  return (
    <div className="h-screen bg-slate-50 w-[240px] p-[26px] border-r border-r-borderColor flex flex-col justify-between">
      <div className="">
        <div className="w-[192px] h-[50px] ">
          {currentOrganization && (
            <img
              src={currentLogo}
              alt="Lilly"
              className="w-[200px] h-[50px] object-contain"
            />
          )}
        </div>
        <div className="mt-5">
          <Menu data={filteredRouteMenu} main />
        </div>
      </div>
      <div>
        <Menu data={settingsMenu} />
        <div className="h-[1px] w-full bg-borderColor mb-10 mt-2 "></div>
        <div >
          <img src="./assets/MaestroHorizontal.png" className="h-[28px]"/>
        </div>
        {/* <Menu data={themeMenu} /> */}
      </div>
    </div>
  );
};

export default Sidebar;
