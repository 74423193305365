import React, { useEffect, useState } from "react";

const Modal = ({
  options,
  setStage,
  setIsOpen,
  setLocalize,
}) => {
  const [country, setCountry] = useState("germany");

  useEffect(() => {
    setLocalize(country);
  }, [country]);

  return (
    <div className="w-full h-screen fixed left-1/2 -translate-y-1/2 top-1/2 -translate-x-1/2 flex justify-center items-center">
      <div className="w-[450px] p-8 py-8 bg-white shadow-md rounded-[16px] z-50">
        <h4 className="text-[20px] leading-[28px] font-semibold">
          Please choose an option for submission
        </h4>

        <div className="grid grid-cols-1 gap-2 my-6 mb-8">
          <button
            className="flex items-center mb-4  w-full"
            onClick={() => setCountry("germany")}
          >
            <div className="w-[30px]">
              <div
                className={`w-[15px] h-[15px] rounded-full border 
               flex justify-center items-center`}
              >
                <div
                  className={`w-[9px] h-[9px] rounded-full 
                 ${country === "germany" ? "bg-secondaryColor" : ""}
                  `}
                ></div>
              </div>
            </div>
            <span className="flex-1 text-left text-fontColor  flex items-center">
              Localize tactics for Germany
              <img
                src="./assets/tactic/germanyFlag.png"
                alt="flag"
                className="w-[30px] h-[20px] ml-[100px]"
              />
            </span>
          </button>
          <button
            className="flex items-center mb-4"
            onClick={() => setCountry("italy")}
          >
            <div className="w-[30px]">
              <div
                className={`w-[15px] h-[15px] rounded-full border 
               flex justify-center items-center`}
              >
                <div
                  className={`w-[9px] h-[9px] rounded-full 
                  ${country === "italy" ? "bg-secondaryColor" : ""}
                  `}
                ></div>
              </div>
            </div>
            <span className="flex-1 text-left text-fontColor  flex items-center">
              Localize tactics for Italy
              <img
                src="./assets/tactic/italyFlag.png"
                alt="flag"
                className="w-[30px] h-[20px] ml-[135px]"
              />
            </span>
          </button>
          <button
            className="flex items-center"
            onClick={() => setCountry("japan")}
          >
            <div className="w-[30px]">
              <div
                className={`w-[15px] h-[15px] rounded-full border 
               flex justify-center items-center`}
              >
                <div
                  className={`w-[9px] h-[9px] rounded-full 
                   ${country === "japan" ? "bg-secondaryColor" : ""}
                 
                  `}
                ></div>
              </div>
            </div>
            <span className="flex-1 text-left text-fontColor flex  items-center">
              Localize tactics for Japan
              <img
                src="./assets/tactic/japanFlag.png"
                alt="flag"
                className="w-[30px] h-[20px] ml-[125px]"
              />
            </span>
          </button>
        </div>
        <div className="flex justify-between">
          <button
            className="px-5 py-2 border border-borderColor rounded-[4px] w-[47%] text-fontColor"
            onClick={() => {
              setIsOpen(false);
              setLocalize("");
            }}
          >
            Cancel
          </button>
          <button
            className="px-5 py-2 border border-primaryColor bg-primaryColor rounded-[4px] w-[47%] text-white"
            onClick={() => {
              setStage("Localize");
              setIsOpen(false);
            }}
          >
            Submit
          </button>
        </div>
      </div>

      <div className="bg-fontColor opacity-20 w-full h-screen absolute top-0 left-0"></div>
    </div>
  );
};

export default Modal;
