import React from "react";
import Layout from "../components/layouts";
import { useNavigate } from "react-router-dom";

import AddUserComponents from "../components/pages/AddUserComponents";
import { useAuth } from "@clerk/clerk-react";

const AddUser = () => {
  const navigate = useNavigate();
  const { orgRole } = useAuth();

  if (!orgRole) {
    // Redirect to the sign-in page if orgRole is not available
    navigate("/sign-in");
    return null;
  }

  return <Layout>{orgRole !== "basic_member" && <AddUserComponents />}</Layout>;
};

export default AddUser;
