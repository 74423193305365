import React, { useEffect, useState } from "react";
const Card = ({ data, setIsZoomViewClick, isZoomViewClick, index }) => {
  const [toggler, setToggler] = useState(false);
  useEffect(() => {
    if (index === 1) {
      setToggler(true);
    }
  }, []);

  return (
    <div className="border border-borderColor overflow-hidden rounded-[16px]">
      <div className="bg-[#E5E7EB] flex rounded-[8px] items-center">
        <div className="flex-1 p-3 text-fontColor text-[20px] font-semibold">
          {data.title}
        </div>
        {/* {toggler && isZoomViewClick === false && (
          <div className="w-[80px] p-2">
            <button className="border border-secondaryColor text-secondaryColor w-full flex items-center rounded-[6px] text-[14px] p-1 px-2">
              <img
                src="./assets/PencilSimpleLine.png"
                alt="Edit"
                className="mr-1"
              />
              Edit
            </button>
          </div>
        )} */}
        <button
          className="w-[40px] p-2"
          onClick={() => {
            setToggler(!toggler);
          }}
        >
          <img
            src={`./assets/${toggler ? "Minus" : "Plus"}.png`}
            alt="Edit"
            className="mr-1"
          />
        </button>
      </div>
      {toggler &&
        data?.data.map((item, i) => (
          <div className="flex rounded-[8px] items-startr" key={i}>
            <div className="w-[20%] p-5 text-fontColor ">{item.title}</div>
            <div className="flex-1 p-5 text-fontColor group-hover:text-white pr-[20px] w-[80%] ">
              {item.ul && (
                <ul className="list-disc px-6 mb-3 ">
                  {item.ul.map((d, ind) => (
                    <li key={ind}>{d}</li>
                  ))}
                </ul>
              )}
              {item.text && item.text}
              {item.image &&  (
                <div
                  className="image-container relative inline-block overflow-hidden"
                  onClick={() => setIsZoomViewClick(true)}
                >
                  <img
                    src={item.image}
                    alt="zoom view"
                    // className="transition-transform duration-300 ease-in-out transform-gpu hover:scale-125"
                  />
                  <div className="overlay absolute top-0 left-0 w-full h-full flex justify-center items-center   cursor-pointer hover:opacity-50 bg-fontColor opacity-0">
                    <img
                      src="./assets/claim/MagnifyingGlassPlus.png"
                      alt="ZOOM"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Card;
