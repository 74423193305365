import React from "react";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";


const Layout = ({ children }) => {
 

  return (
      <div className="w-full flex">
        <Sidebar />
        <div className="h-screen overflow-hidden bg-slate-300 flex-1">
          <Header />
          <div className="w-full h-[calc(100vh_-_90px)] overflow-y-scroll">
            {children}
          </div>
        </div>
      </div>
  );
};

export default Layout;
