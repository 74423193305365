import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../App";
import { getImage } from '../../../../utils/ajax-proxy'

const SocialMediaCard = ({ data, localize, setIsRationaleClick , claim_data, selected, reference_dictionary}) => {
  const [toggler, setToggler] = useState(false);
  const { setSelectedCard } = useContext(Context);
  const [tacticImage, setImage] = useState("");
  const [referenceids, setReferenceIds] = useState(['REF-54984', 'REF-49941',  'REF-46154', 'REF-40577', 'REF-37579']);
  const [theData, setTheData] = useState(null);

  useEffect(() => {
    if (localize !== "") { 
      setToggler(false);
      fetchImage( localize, 2)
    } else {
      fetchImage(localize == "" ? 'us' : localize, 2)
    }
  }, []);

  useEffect(() => {
    if(!claim_data) return;
    setTheData(claim_data[selected]);
    if (claim_data[selected]?.references) {
      setReferenceIds(JSON.parse(claim_data[selected]?.references));
    }
  }, [claim_data, selected]);

  const fetchImage = async(localize, segment) => {
    let response = await getImage(localize, segment); 
    if(response?.status == 200) {
      setImage(response?.data?.image)
    }  else {
      setImage("") 
    }
  }
  const togglerHandler = (value) => {
    setToggler(value);
    fetchImage(localize == "" ? 'us' : localize, !value ? 1 : 2)
  }

  const getLabelByReference = (reference) => reference_dictionary.find((item) => item.PubID == reference);

  return (
    <div className=" overflow-hidden">
      <p className="text-base font-normal  mb-2 text-fontColor">Subsegment</p>
      <div className="bg-[#E5E7EB] flex rounded-[8px] items-center">
        <div className="flex bg-white w-full gap-4">
          <div
            className={`border border-borderColor rounded-[8px] p-2 px-8 pl-6 flex items-center cursor-pointer gap-6 ${
              !toggler && "bg-lightGreen"
            }`}
            onClick={() => {
              setToggler(false);
              setSelectedCard("research");
              togglerHandler(false)
            }}
          >
            {localize === "" ? (
              <>
                <img
                  src="./assets/tactic/communityLogo.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">
                  Community Practitioners (Global)
                </p>
              </>
            ) : localize === "germany" ? (
              <>
                <img
                  src="./assets/tactic/germanyFlag.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">Researchers & Academics</p>
              </>
            ) : localize === "italy" ? (
              <>
                <img
                  src="./assets/tactic/italyFlag.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">Researchers & Academics</p>
              </>
            ) : (
              <>
                <img
                  src="./assets/tactic/japanFlag.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">Researchers & Academics</p>
              </>
            )}
          </div>
          <div
            className={`border border-borderColor rounded-[8px] p-2 px-8 pl-6 flex items-center cursor-pointer gap-6 ${
              toggler && "bg-lightGreen"
            }`}
            onClick={() => {
              setToggler(true);
              setSelectedCard("socialMedia");
              togglerHandler(true)
            }}
          >
            {localize === "" ? (
              <>
                <img
                  src="./assets/tactic/communityLogo.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">
                  Patient Advocates (Global)
                </p>
              </>
            ) : localize === "germany" ? (
              <>
                <img
                  src="./assets/tactic/germanyFlag.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">Social Media Savvy</p>
              </>
            ) : localize === "italy" ? (
              <>
                <img
                  src="./assets/tactic/italyFlag.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">Social Media Savvy</p>
              </>
            ) : (
              <>
                <img
                  src="./assets/tactic/japanFlag.png"
                  alt="communityLogo"
                  className="w-[30px] h-[20px]"
                />
                <p className="text-base font-normal">Social Media Savvy</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-12">
        <p className="text-base mt-4 mb-[14px] font-normal text-fontColor">
          MLR Confidence
          <span className="text-base font-semibold text-green pl-2 underline">
            95%
          </span>
        </p>
        <button
          className="flex  items-center h-[30px] text-base  p-3  mt-4 w-[128px] text-secondaryColor border border-secondaryColor rounded-md gap-2 justify-center"
          onClick={() => {
            setIsRationaleClick(true);
          }}
        >
          <img src="./assets/tactic/Sparkle.png" alt="closeIcon" />
          Rationale
        </button>
      </div>

      <div className="bg-lightGray">
        <div className="p-12">
          <div className="bg-white py-5 px-2">
            <div className="flex gap-3 items-center">
              <p className="text-lg font-medium">To:</p>
              <p className="text-lg">
                {"<Physician@gmail.com(one email address only)>"}
              </p>
            </div>
            <div className="flex gap-3 items-center">
              <p className="text-lg font-medium">From options:</p>
              <p className="text-lg">Lilly@lilly.gmail</p>
            </div>
            <div className="flex gap-3 items-center mt-10">
              <p className="text-lg font-medium">Linea Oggetto:</p>
              <p className="text-lg">
                Vedi gli ultimi dati di efficacia per Verzenio
              </p>
            </div>
            <div className="flex gap-3 items-center mt-10">
              <p className="text-lg font-medium">Preintestazione:</p>
              <p className="text-lg">Avete pazienti ad alto rischio ?</p>
            </div>
          </div>
          <div className="flex justify-center items-center text-lg font-medium mt-10">
            Per visualizzare questa e-mail in un browser,{" "}
            <a href="#" className="text-primaryColor text-lg ml-1 underline">
              fare clic qui.
            </a>
          </div>
        </div>
        <div className=" mx-12 bg-white">
          <img src="./assets/tactic/Tactic.png" alt="" />
        </div>
        <div className="mx-12 bg-white mt-5 p-5">
          {/* <img src="./assets/tactic/tacticImage.png" alt="" /> */}
          <img src={`data:image/png;base64,${tacticImage}`} alt="" />
          <h1 className="text-4xl text font-bold text-darkBlue mt-5">
            La riduzione del rischio di recidiva continua oltre i 2 anni di
            trattamento con Verzenios.1
          </h1>
          <h4 className="text-2xl text font-medium text-darkGreen pt-3">
            Verzenios + ET: il primo e unico inibitore di CDK4 & 6 approvato che
            riduce il rischio di malattia metastatica fino a 4 anni.1-6
          </h4>
          <p className="text-2xl text font-medium text-darkGreen pt-3">
            Sopravvivenza libera da recidiva a distanza al follow up a 4 anni
          </p>
        </div>
        <div className="mx-12 bg-white p-5">
          <img src="./assets/tactic/ZoomView.png" alt="zoomView" />
          <p className="font-medium text-lg">
            CDK4 & 6-chinasi ciclina-dipendenti 4 e 6; IC-Intervallo di
            confidenza; DRFS=sopravvivenza libera da ricaduta a distanza;
            EBC=carcinoma mammario iniziale; ET-Terapia Endocrina; HER2-
            recettore del fattore umano crescita epidermico di tipo 2-; HR=
            hazard ratio; HR+= positivo ai recettori ormonali;
            ITT-intention-to-treat
          </p>
          <p className="font-medium text-lg mt-7">
            L'alto rischio di recidiva nel carcinoma mammario in fase iniziale è
            stato definito come pazienti con 4 o più linfonodi ascellari
            positivi o 1-3 linfonodi ascellari positivi e dimensioni del tumore
            maggiori o uguali a 5 cm, o grado istologico 3 o entrambi.²
          </p>
        </div>

        <div className="mx-12 bg-white mt-5 p-5">
          <h4 className="font-medium text-xl">Riferimenti:</h4>
           { referenceids.map((ref) => getLabelByReference(ref)?.Abbreviation)?.map((item, index) => (<p>{index+1}.{item}</p>))}
        </div>
        <div className="mx-12">
          <p className="text-lg font-bold text-darkGreen p-5">
            Clicchi qui per RCP Verzenios®
          </p>
          <p className="text-lg font-bold text-darkGreen px-5">
            Clicchi qui per classe, prezzo e rimborsabilità di Verzenios®
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            PP-AL-IT-XXXX - Data di deposito AIFA XX/XX/XXXX
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            Questa comunicazione diretta esclusivamente a medici da Lilly o per
            conto di Lilly e non può essere inoltrata a terzi. La comunicazione
            contiene informazioni su medicinali e/o dispositivi Lilly
            autorizzati in Italia e, accedendovi, Lei dichiara di essere un
            medico che esercita la professione in Italia. Qualora lei non sia un
            medico abilitato in Italia o non abbia ricevuto questa comunicazione
            da Lilly o per conto di Lilly, è tenuto a cancellarla
            permanentemente. Lilly non si assume la responsabilità della
            manipolazione o dell'alterazione dei contenuti di questa pagina e in
            generale dell'uso scorretto o illegale delle informazioni divulgate.
            L'accesso alle informazioni, così come l'uso che di esse potrà
            farsi, è sotto la esclusiva responsabilità del ricevente.
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            Per informazioni riguardo alla Privacy e al trattamento dei dati
            personali da parte di Lilly, si prega di fare riferimento all'
            <span className="text-darkGreen">
              <a href="#">Informativa sulla Privacy.</a>
            </span>
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            La presente comunicazione è regolata dalla legge italiana.
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            Copyright ©2023 Eli Lilly Italia S.p.A.-Società a socio unico Gruppo
            Eli Lilly and Company - Sede Legale: Via Gramsci 731/733 Sesto
            Fiorentino (FI) - PEC:{" "}
            <span className="text-darkGreen">
              <a href="#">direzione@pec.lilly</a>
            </span>
            it Capitale Sociale Euro 69.921.731,00 i.v. - Reg. Imp. Trib.
            Firenze N. 14348 C.C.I.A.A. Firenze - Cod. Fisc. e Part. IVA
            00426150488
          </p>
          <p className="mt-4 font-medium text-lg px-5">
            Per non ricevere più le nostre comunicazioni{" "}
            <span className="text-darkGreen">
              <a href="#">clicca qui</a>
            </span>
          </p>
        </div>
        <div className="mx-12 flex justify-end mt-4">
          <img src="./assets/Logo.png" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaCard;
