import React, { useContext, useState } from "react";
import { Context } from "../../../App";

const lateMajority = [
  ["Efficacy(HQ Email)", "./assets/tactic/Thumbnail1.png"],
  ["P2P Confirmation (VAE)", "./assets/tactic/Thumbnail2.png"],
  ["Savings Card (VAE)", "./assets/tactic/Thumbnail3.png"],
  ["Medscape Email Template (SOA)", "./assets/tactic/Thumbnail4.png"],
  ["P2P Presentation (Slide Deck)", "./assets/tactic/Thumbnail5.png"],
  ["IVA", "./assets/tactic/Thumbnail6.png"],
];

const digitalOnly = [
  ["Efficacy(HQ Email)", "./assets/tactic/Thumbnail7.png"],
  ["eP2P Confirmation (VAE)", "./assets/tactic/Thumbnail8.png"],
  ["Text Chat (Flex Connect)", "./assets/tactic/Thumbnail9.png"],
  ["Medscape Email Template (SOA)", "./assets/tactic/Thumbnail10.png"],
];

const UpdateTactics = () => {
  const { setStage, setTacticValue, setSelectedSegments, tacticValue } =
    useContext(Context);
  const [lateMajorityChecked, setLateMajorityChecked] = useState([]);
  const [digitalOnlyChecked, setDigitalOnlyChecked] = useState([]);
  const [isDigitalOnlyClick, setIsDigitalOnlyClick] = useState(false);

  const handleLateMajorityCheck = (value) => {
    const isExist = lateMajorityChecked.filter((item) => {
      return item === value;
    });
    if (isExist.length === 0) {
      setLateMajorityChecked([...lateMajorityChecked, value]);
    } else {
      const d = lateMajorityChecked.filter((item) => {
        return item !== value;
      });
      setLateMajorityChecked(d);
    }
  };

  const isLateMajorityChecked = (value) => {
    const d = lateMajorityChecked.filter((item) => {
      return item === value;
    });
    if (d.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleDigitalOnlyCheck = (value) => {
    const isExist = digitalOnlyChecked.filter((item) => {
      return item === value;
    });
    if (isExist.length === 0) {
      setDigitalOnlyChecked([...digitalOnlyChecked, value]);
    } else {
      const d = digitalOnlyChecked.filter((item) => {
        return item !== value;
      });
      setDigitalOnlyChecked(d);
    }
  };

  const isDigitalOnlyChecked = (value) => {
    const d = digitalOnlyChecked.filter((item) => {
      return item === value;
    });
    if (d.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="w-[750px]">
      <div className="flex items-center py-[20px]">
        <p className="text-fontColor font-semibold">Create a Tactic</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor">Update Tactics</p>
      </div>
      <div className="w-full border border-borderColor rounded-[16px] p-[32px]">
        <h4 className="text-[24px] font-bold">Select Options</h4>
        <p className="text-fontColor mt-1">
          Update campaign tactics for Efficacy CME
        </p>
        <div className="flex gap-7">
          <button
            className={`text-base mt-8 border border-borderColor rounded-[8px] py-[6px] px-[20px] ${
              !isDigitalOnlyClick ? "bg-lightGreen" : ""
            }`}
            onClick={() => {
              isDigitalOnlyClick && setIsDigitalOnlyClick(!isDigitalOnlyClick);
              setSelectedSegments("Late Majority");
            }}
          >
            Late Majority
          </button>
          <button
            className={`text-base mt-8 border border-borderColor rounded-[8px]  py-[6px] px-[20px]  ${
              isDigitalOnlyClick ? "bg-lightGreen" : ""
            }`}
            onClick={() => {
              !isDigitalOnlyClick && setIsDigitalOnlyClick(!isDigitalOnlyClick);
              setSelectedSegments("Digital Only");
            }}
          >
            Digital Only
          </button>
        </div>

        {!isDigitalOnlyClick && (
          <div className="flex mt-5 justify-between w-full flex-wrap  gap-5  ">
            {lateMajority.map((item, i) => (
              <div
                className={`w-[31%] border-[#E5E7EB] rounded-md border bg-lightGray cursor-pointer  ${
                  tacticValue && tacticValue === item[0]
                    ? "border-secondaryColor"
                    : ""
                } `}
                onClick={() => {
                  handleLateMajorityCheck(item);
                  setTacticValue(item[0]);
                }}
              >
                <div className="p1">
                  <img src={item[1]} alt="" className="p-1" />
                </div>
                <button
                  key={i}
                  type="button"
                  className={`text-darkFontColor p-4 font-medium ${
                    isLateMajorityChecked(item) ? "border-secondaryColor" : ""
                  } text-left`}
                >
                  <span
                    className={` ${
                      isLateMajorityChecked(item)
                        ? "text-darkFontColor"
                        : "text-darkFontColor"
                    }`}
                  >
                    {item[0]}
                  </span>
                </button>
              </div>
            ))}
          </div>
        )}

        {isDigitalOnlyClick && (
          <div className="flex mt-5 justify-between w-full flex-wrap gap-5">
            {digitalOnly.map((item, i) => (
              <div
                className={`w-[31%] border-[#E5E7EB] rounded-md border bg-lightGray cursor-pointer  ${
                  tacticValue && tacticValue === item[0]
                    ? "border-secondaryColor"
                    : ""
                } `}
                onClick={() => {
                  handleLateMajorityCheck(item);
                  setTacticValue(item[0]);
                }}
              >
                <div className="p1">
                  <img src={item[1]} alt="" className="p-1" />
                </div>
                <button
                  key={i}
                  type="button"
                  className={`text-darkFontColor p-4 font-medium ${
                    isLateMajorityChecked(item) ? "border-secondaryColor" : ""
                  } text-left`}
                >
                  <span
                    className={` ${
                      isDigitalOnlyChecked(item)
                        ? "text-white"
                        : "text-fontColor"
                    }`}
                  >
                    {item[0]}
                  </span>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-end mt-[80px] mb-[32px]">
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8"
          onClick={() => setStage("")}
        >
          Back
        </button>
        {tacticValue !== "" && (
          <button
            className="bg-primaryColor text-white rounded-[4px] p-3 px-8 ml-5"
            onClick={() => setStage("Review")}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default UpdateTactics;
