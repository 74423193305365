import React, { useEffect, useState } from "react";

const GeneratingTactic = ({ setStage }) => {
  const [text, setText] = useState(
    <>
      <span className="font-bold">
        Analyzing historical tactic performance with{" "}
      </span>
      segments, competitor claims and messaging, and market research
    </>
  );
  const text2 = (
    <div>
      <strong>Assembling tactics</strong> according to{" "}
      <strong>regulatory requirements</strong> within global templates
    </div>
  );

  const [textIndex, setTextIndex] = useState(1);
  const [imageUrl, setImageUrl] = useState("./assets/tactic/tacticImage1.png");

  const textMessages = [
    "Analyzing historical tactic performance with segments, competitor claims and messaging, and market research",
    <span className="font-bold text-fontColor">
      Identifying relevant claims
    </span>,
    <span className="font-bold text-fontColor">
      Generating text and images
    </span>,
    text2,
  ];

  const imageUrls = [
    "./assets/tactic/tacticImage1.png",
    "./assets/tactic/tacticImage2.png",
    "./assets/tactic/tacticImage3.png",
    "./assets/tactic/tacticImage4.png",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update text every second with the next message
      console.log(textIndex);
      if (textIndex < textMessages.length) {
        setText(textMessages[textIndex]);
        setTextIndex((prevIndex) => prevIndex + 1);
        setImageUrl(imageUrls[textIndex]);
      } else {
        clearInterval(intervalId); // Clear interval after displaying all texts
        setStage("complete");
      }
    }, 2000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [textIndex, textMessages.length, imageUrls]);

  return (
    <div className="w-[750px] ">
      <div className="w-full border border-borderColor rounded-[16px]  h-[583px] flex flex-col py-[72px] px-8 items-center mt-[72px]">
        <div className="">
          <div>
            <p className="text-2xl font-semibold">Generating Tactics</p>
            <p className="wi-full text-center text-2xl font-normal">
              Text + Images
            </p>
          </div>
        </div>
        <img
          src={imageUrl}
          alt="tacticImage"
          className={`w-[133.33px] h-[133.33px] mt-5 ${
            textIndex === 4 ? "mt-3" : ""
          }`}
        />
        <p className="text-fontColor mt-5 max-w-[450px] text-center text-base ">
          {text}
        </p>
      </div>
    </div>
  );
};

export default GeneratingTactic;
