import {
  useAuth,
  useClerk,
  useOrganization,
  useOrganizationList,
  useUser,
} from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import Select from "react-select";
import MyAddUserModal from "../../common/AddUser/MyAddUserModal";
import Table from "../../common/Tables/Table";
import EditUserModal from "../../common/EditUserModal/EditUserModal";

export default function AddUserComponents() {
  const { setActive, organizationList } = useOrganizationList();
  const [organizationId, setOrganizationId] = useState(null);
  const { organization, ...rest } = useOrganization();
  const { orgRole } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [members, setMembers] = useState();
  const [isEditUserClicked, setIsEditUserClicked] = useState(false);
  const [userData, setUserData] = useState(null);
  const [editStatus,setEditStatus]=useState(false)

  const handleOrgChange = (e) => {
    setActive({ organization: e.value });
    setOrganizationId(e.value);
  };

  useEffect(() => {
    if (organization) {
      organization.getMemberships().then((res) => {
        setMembers(res);
      });
    }
  }, [organization, editStatus]);

  return (
    <div>
      {isEditUserClicked && (
        <EditUserModal
          setIsEditUserClicked={setIsEditUserClicked}
          userData={userData}
          setEditStatus={setEditStatus}
          editStatus={editStatus}
        />
      )}
      <h1>
        <title>Clerk Organizations Demo</title>
      </h1>
      <div className=" w-1/2 h-[calc(100vh-238px)] m-auto">
        <div className="flex items-center justify-end mt-5 ">
          {orgRole === "admin" && (
            <div onClick={() => setIsModalOpen(!isModalOpen)}>
              <button className="border border-borderColor font-semibold text-sm rounded-[4px] p-3 px-2 pl-3 flex items-center ml-auto bg-primaryColor text-white">
                <span>Add User</span>
              </button>
            </div>
          )}
        </div>
        {members && (
          <Table
            data={members}
            headings={["Name", "Email", "Roles"]}
            setIsEditUserClicked={setIsEditUserClicked}
            setUserData={setUserData}
          />
        )}
      </div>
      {isModalOpen && (
        <MyAddUserModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
    </div>
  );
}

function OrganizationInfo() {
  const {
    organization: currentOrganization,
    membership,
    isLoaded,
  } = useOrganization();

  if (!isLoaded || !currentOrganization) {
    return null;
  }

  // const isAdmin = membership.role === "admin";
  return (
    <div>
      <div className="text-primaryColor font-semibold border-primaryColor border-2 p-1 rounded-md flex items-center gap-2">
        <div className="w-3 bg-green h-3 rounded-full"></div>
        {currentOrganization.name}
      </div>
    </div>
  );
}

function createOrganizationOptions(organizationList) {
  return organizationList.map(({ organization }) => ({
    value: organization.id,
    label: organization.name,
  }));
}
