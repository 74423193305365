import React, { useContext } from "react";
import { Context } from "../../../../App";

const RationaleModal = ({ setIsRationaleClick }) => {
  const { localize } = useContext(Context);
  return (
    <div className="w-full h-screen fixed left-1/2 -translate-y-1/2 top-1/2 -translate-x-1/2 flex justify-center items-center">
      <div className="w-[400px] p-8 py-8 bg-white shadow-md rounded-[16px] z-50  ">
        <div className="flex border-b border-mediumGray pb-8">
          <h4 className="text-2xl leading-[28px] font-semibold  ">
            MLR Confidence Score Rationale
          </h4>

          <img
            src="./assets/tactic/closeIcon.png"
            alt="closeIcon"
            className="w-6 h-6 mt-1 cursor-pointer"
            onClick={() => {
              setIsRationaleClick(false);
            }}
          />
        </div>
        <div className="pt-8">
          <h4 className="text-base font-bold underline">Tactic Includes:</h4>
          <ul className="list-disc">
            <li className="font-bold ml-7">Previously Approved:</li>
            <li className="ml-14 font-normal">Claim</li>
            <li className="ml-14 font-normal">Logo</li>
            <li className="ml-14 font-normal">Template</li>
          </ul>

          <ul className="list-disc mt-5">
            <li className="font-bold ml-7">Previously Used</li>
            <li className="ml-14 font-normal">Call to Action</li>
            <li className="ml-14 font-normal">Footer</li>
          </ul>
          <ul className="list-disc mt-5">
            <li className="font-bold ml-7">Net New Content:</li>
            <li className="ml-14 font-normal">
              AI-generated image based on brand guidelines
            </li>
            {localize && (
              <li className="ml-14 font-normal">
                Text adapted for cultural nuance
              </li>
            )}
          </ul>
          <h4 className="font-bold text-base mt-5">
            Overall MLR Confidence Score:{" "}
            <span className="text-green"> {localize ? "90%" : "95%"}</span>
          </h4>
        </div>
      </div>
      <div className="bg-fontColor opacity-20 w-full h-screen absolute top-0 left-0"></div>
    </div>
  );
};

export default RationaleModal;
