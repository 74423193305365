import React from "react";

const Submitting = () => {
  return (
    <div className="w-[750px]">
      <div className="w-full border border-borderColor rounded-[16px] p-[32px] h-[320px] flex flex-col justify-center items-center mt-20">
        <div
          className="w-[50px] h-[50px] rounded-full flex justify-center items-center animate-spin"
          style={{
            background: "conic-gradient(#00A4C1 90deg, #E5E7EB 40deg)",
          }}
        >
          <div className="w-[40px] h-[40px] bg-white rounded-full"></div>
        </div>
        <h4 className="text-[24px] mt-[35px] font-bold">
          Localizing Tactics...
        </h4>
        <p className="text-fontColor mt-[15px]">
          Please wait while we are submitting your request.
        </p>
      </div>
    </div>
  );
};

export default Submitting;
