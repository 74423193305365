import React from "react";
import { UserButton, useUser } from "@clerk/clerk-react";

const User = () => {
const {user}=useUser()
console.log(user)

  return (
    <button className="flex items-center ml-8 gap-4">
      <div className="flex flex-col justify-center text-right pr-4">
        <p className="m-0 text-[14px] font-semibold text-[#111928]">
          {user?.fullName?user.fullName:user?.primaryEmailAddress?.emailAddress}
         
        </p>
      </div>
      {/* <div className="bg-[#F5F6F7] w-[36px] h-[36px] rounded-[6px] flex justify-center items-center relative ml-3 overflow-hidden">
        <img src="./assets/User.png" alt="notification" className="w-full" />
      </div> */}
      <UserButton />

      <div className="w-[30px] h-[30px] ml-4 mt-2">
        <img src="./assets/MaestroIcon.png" />
      </div>
    </button>
  );
};

export default User;
