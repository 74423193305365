import React from "react";

const Action = ({ option, index, setIsViewRationaleClick, setOption }) => {
  return (
    <div
      className={`bg-white w-[220px] absolute right-0 top-full rounded-[10px] p-[16px] z-50 ${
        option === index ? "block" : "hidden"
      }`}
      style={{
        boxShadow: "0px 4px 40px 0px rgba(24, 53, 98, 0.15)",
      }}
    >
      <button
        className="flex items-center hover:bg-[#F5F6F7] p-2 w-full rounded-[6px]"
        onClick={() => {
          setIsViewRationaleClick(true);
          setOption(null)
        }}
      >
        <img
          src="./assets/actions/idea.png"
          alt="Edit"
          className="w-[23px] h-[23px]"
        />
        <span className="ml-2">View Rationale</span>
      </button>
      <button className="flex items-center hover:bg-[#F5F6F7] p-2 w-full rounded-[6px]">
        <img src="./assets/actions/PencilSimpleLine.png" alt="Edit" />
        <span className="ml-2">Edit</span>
      </button>
      <button className="flex items-center hover:bg-[#F5F6F7] p-2 w-full rounded-[6px]">
        <img src="./assets/actions/ArrowsMergeRight.png" alt="Edit" />
        <span className="ml-2">Merge</span>
      </button>
      <button className="flex items-center hover:bg-[#F5F6F7] p-2 w-full rounded-[6px]">
        <img src="./assets/actions/Trash.png" alt="Edit" />
        <span className="ml-2">Remove</span>
      </button>
    </div>
  );
};

export default Action;
