import React from "react";

const Processing = () => {
  return (
    <div className="w-[750px]">
      <p className="py-[20px] text-fontColor">Create a Claim</p>
      <div className="w-full border border-borderColor rounded-[16px] p-[32px] h-[330px] mt-5 flex flex-col justify-center items-center">
        <div
          className="w-[50px] h-[50px] rounded-full flex justify-center items-center animate-spin"
          style={{
            background: "conic-gradient(#00A4C1 90deg, #E5E7EB 40deg)",
          }}
        >
          <div className="w-[40px] h-[40px] bg-white rounded-full"></div>
        </div>
        <h4 className="text-[24px] mt-[35px] font-bold">Processing</h4>
        <p className="text-fontColor mt-[15px]">
          Please wait while we are processing your request.
        </p>
      </div>
    </div>
  );
};

export default Processing;
