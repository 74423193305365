import { useOrganizationList } from "@clerk/clerk-react";
import { useContext, useEffect, useState } from "react";
import CreateOrganizationModal from "../../common/CreateOrganizationModal/CreateOrganizationModal";
import { Context } from "../../../App";
export default function CreateOrganizationComponents() {
  const [isAddOrganizationClicked, setIsAddOrganizationClicked] =
    useState(false);

  const [orgData, setOrgData] = useState();
  const [orgId, setorgId] = useState();
  const [edit, setEdit] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const {
    organizationListData,
    setOrganizationListData,
    selectedImage,
    setSelectedImage,
    setAddOrganizationResponse,
  } = useContext(Context);

  const [image, setImage] = useState("");

  const updateList = () => {
    setUpdateFlag(!updateFlag);
  };

  const { organizationList, isLoaded } = useOrganizationList();

  useEffect(() => {
    setOrganizationListData(organizationList);
  }, [isLoaded]);

  const getAllOrganization = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}getOrganization`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const data = await response.json();
      return data;

      // Save response data in state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-[50%] m-auto flex justify-center h-[90%]">
      <div className="w-full xl:w-full mb-12 xl:mb-0 mx-auto mt-6 h-[100%]">
        <div className=" flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded h-[100%] ">
          <div className="block w-full overflow-x-auto">
            <button
              className="border border-borderColor font-semibold text-sm rounded-[4px] p-3 px-2 pl-3 flex items-center ml-auto bg-primaryColor text-white "
              onClick={() => {
                setIsAddOrganizationClicked(!isAddOrganizationClicked);
                setAddOrganizationResponse(false);
                setEdit(false);
                setOrgData(null);
                setSelectedImage(null);
              }}
            >
              <span>Add Organization</span>
            </button>
            {organizationListData?.length > 0 ? (
              <div className="border  rounded-[4px] border-borderColor  mt-10">
                <table className="items-center bg-transparent w-full border-collapse ">
                  <thead className="w-full">
                    <tr className="border border-solid border-[#E4E4E4] border-t-0 border-l-0 border-r-0 ">
                      <th className="px-6 font-bold bg-blue text-blue align-middle  py-3 t border-t-0 border-l-0 border-r-0 whitespace-nowrap text-left ">
                        Organization
                      </th>
                      <th className="px-6 font-bold bg-blue  text-blue align-middle text-base flex  items-center gap-2 justify-end pr-[92px]  py-3  border-t-0 border-l-0 border-r-0 whitespace-nowrap  ">
                        Edit
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <>
                      {organizationListData?.map((item, index) => {
                        return (
                          <tr key={item.organization.id}>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0  whitespace-nowrap p-3  font-medium text-sm">
                              {item.organization.name}
                            </td>

                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm text-end whitespace-nowrap p-3 cursor-pointer">
                              <button
                                className="border ml-auto border-borderColor rounded-[4px] p-2 px-4 mr-6 pl-3 flex items-center bg-primaryColor text-white"
                                onClick={() => {
                                  setOrgData({
                                    name: item.organization.name,
                                    url:
                                      item.organization.logoUrl ||
                                      item.organization.imageUrl ||
                                      item.organization.logo_url ||
                                      item.organization.image_url,
                                    public_metadata:
                                      item.organization.publicMetadata ||
                                      item.organization.public_metadata, // updated data coming in differnet key
                                    logo_url:
                                      item.organization.logoUrl ||
                                      item.organization.logo_url,
                                  });
                                  setEdit(true);
                                  setorgId(item.organization.id);
                                  setIsAddOrganizationClicked(
                                    !isAddOrganizationClicked
                                  );
                                }}
                              >
                                <span className="ml-1">Manage</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="mt-10 font-extrabold">No Organization available</p>
            )}
          </div>
        </div>
      </div>
      {isAddOrganizationClicked && (
        <CreateOrganizationModal
          setIsAddOrganizationClicked={setIsAddOrganizationClicked}
          isAddOrganizationClicked={isAddOrganizationClicked}
          orgData={orgData}
          isEditMode={edit}
          orgId={orgId}
          updateList={updateList}
          setOrganizationListData={setOrganizationListData}
          organizationListData={organizationListData}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          setImage={setImage}
          image={image}
          getAllOrganization={getAllOrganization}
        />
      )}
    </div>
  );
}
