import React, { useContext, useEffect } from "react";
import Filled from "./Filled";
import Processing from "./Processing";
import CreateImage from "./CreateImage";
import { Context } from "../../../App";

const CreateImageComponents = () => {
  const { stage, setStage } = useContext(Context);

  const returnComponents = () => {
    if (stage === "") {
      return <Filled setStage={setStage} />;
    }
    if (stage === "Processing") {
      return <Processing setStage={setStage} />;
    }
    if (stage === "CreateImage") {
      return <CreateImage setStage={setStage} />;
    } else {
      setStage("");
    }
  };

  useEffect(() => {
    if (stage === "Processing") {
      setTimeout(() => {
        setStage("CreateImage");
      }, 2000);
    }
  }, [stage, setStage]);

  return (
    <div className="px-[32px] flex justify-center">{returnComponents()}</div>
  );
};

export default CreateImageComponents;
