import React, { useContext, useEffect } from "react";
import Filled from "./Filled";
import Review from "./Review";
import Analyzing from "./Analyzing";
import UpdateTactics from "./UpdateTactics";
import Submitting from "./Submitting";
import Submitted from "./Submitted";
import { Context } from "../../../App";
import Localize from "./Localize";
import GeneratingTactic from "./GeneratingTactic";
import Completed from "./Completed";

const CreateTacticComponents = () => {
  const { stage, setStage } = useContext(Context);

  const returnComponents = () => {
    if (stage === "") {
      return <Filled setStage={setStage} />;
    } else if (stage === "GeneratingTactic") {
      return <GeneratingTactic setStage={setStage} />;
    } else if (stage === "complete") {
      return <Completed />;
    } else if (stage === "Analyzing") {
      return <Analyzing setStage={setStage} />;
    } else if (stage === "UpdateTactics") {
      return <UpdateTactics setStage={setStage} />;
    } else if (stage === "Review") {
      return <Review setStage={setStage} />;
    } else if (stage === "Localize") {
      return <Localize setStage={setStage} />;
    } else if (stage === "Submitting") {
      return <Submitting setStage={setStage} />;
    } else if (stage === "Submitted") {
      return <Submitted setStage={setStage} />;
    } else {
      setStage("");
    }
  };

  useEffect(() => {
    if (stage === "Analyzing") {
      setTimeout(() => {
        setStage("UpdateTactics");
      }, 2000);
    }
    if (stage === "Submitting") {
      setTimeout(() => {
        setStage("Submitted");
      }, 2000);
    }
    if (stage === "Localize") {
      setTimeout(() => {
        setStage("Review");
      }, 2000);
    }

    if (stage === "complete") {
      setTimeout(() => {
        setStage("Analyzing");
      }, 2000);
    }
  }, [stage, setStage]);

  return (
    <div className="px-[32px] flex justify-center">{returnComponents()}</div>
  );
};

export default CreateTacticComponents;
