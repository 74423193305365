import React, { useContext, useEffect, useState } from "react";
import CountryDataCard from "../../common/CountryDataCard";
import Lines from "../../common/CountryDataCard/Lines";
import { Context } from "../../../App";
import ConfirmModal from "./ConfirmModal";

const GlobalClaimTree = () => {
  const { setStage, data, selected, reference_dictionary } = useContext(Context);
  const [theData, setTheData] = useState(null);
  const [references, setReferences] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const getLabelByReference = (reference) =>
  reference_dictionary.find((item) => item.PubID == reference);

  const globalData = {
    country: "Global",
    image: "./assets/world.png",
    content: theData?.modeloutput.English.heading,
    data: [
      {
        one: references[0],
        two: getLabelByReference(references[0])?.Abbreviation,
      },
      {
        one: references[1],
        two: getLabelByReference(references[1])?.Abbreviation,
      },
      {
        one: references[2],
        two: getLabelByReference(references[2])?.Abbreviation,
      },
      {
        one: references[3],
        two: getLabelByReference(references[3])?.Abbreviation,
      },
      {
        one: references[4],
        two: getLabelByReference(references[4])?.Abbreviation,
      },
    ],
  };

  const countriesData = [
    {
      country: "US",
      image: "./assets/countries/us.png",
      content: theData?.modeloutput.English.heading,
      data: [
        {
          one: references[0],
          two: getLabelByReference(references[0])?.Abbreviation,
        },
        {
          one: references[1],
          two: getLabelByReference(references[1])?.Abbreviation,
        },
        {
          one: references[2],
          two: getLabelByReference(references[2])?.Abbreviation,
        },
        {
          one: references[3],
          two: getLabelByReference(references[3])?.Abbreviation,
        },
        {
          one: references[4],
          two: getLabelByReference(references[4])?.Abbreviation,
        },
      ],
    },
    {
      country: "Italy",
      image: "./assets/countries/italy.png",
      content: theData?.modeloutput.Italian.heading,
      data: [
        {
          one: references[0],
          two: getLabelByReference(references[0])?.Abbreviation,
        },
        {
          one: references[1],
          two: getLabelByReference(references[1])?.Abbreviation,
        },
        {
          one: references[2],
          two: getLabelByReference(references[2])?.Abbreviation,
        },
        {
          one: references[3],
          two: getLabelByReference(references[3])?.Abbreviation,
        },
        {
          one: references[4],
          two: getLabelByReference(references[4])?.Abbreviation,
        },
      ],
    },
    {
      country: "Germany",
      image: "./assets/countries/germany.png",
      content: theData?.modeloutput.German.heading,
      data: [
        {
          one: references[0],
          two: getLabelByReference(references[0])?.Abbreviation,
        },
        {
          one: references[1],
          two: getLabelByReference(references[1])?.Abbreviation,
        },
        {
          one: references[2],
          two: getLabelByReference(references[2])?.Abbreviation,
        },
        {
          one: references[3],
          two: getLabelByReference(references[3])?.Abbreviation,
        },
        {
          one: references[4],
          two: getLabelByReference(references[4])?.Abbreviation,
        },
      ],
    },
    {
      country: "Japan",
      image: "./assets/countries/japan.png",
      content: theData?.modeloutput.Japanese.heading,
      data: [
        {
          one: references[0],
          two: getLabelByReference(references[0])?.Abbreviation,
        },
        {
          one: references[1],
          two: getLabelByReference(references[1])?.Abbreviation,
        },
        {
          one: references[2],
          two: getLabelByReference(references[2])?.Abbreviation,
        },
        {
          one: references[3],
          two: getLabelByReference(references[3])?.Abbreviation,
        },
        {
          one: references[4],
          two: getLabelByReference(references[4])?.Abbreviation,
        },
      ],
    },
  ];

  useEffect(() => {
    setTheData(data[selected]);
    if (data[selected]?.references) {
      setReferences(JSON.parse(data[selected]?.references));
    }
  }, [data, selected]);

  return (
    <div className="w-full">
      {confirmModal && (
        <ConfirmModal
          setConfirmModal={setConfirmModal}
          confirmModal={confirmModal}
        />
      )}
      <div className="flex items-center py-[20px]">
        <p className="text-fontColor font-semibold">Create a Claim</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor font-semibold">Choose Claim</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor font-semibold">Review</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor">Global Claim Tree</p>
      </div>
      <div className="w-full mt-8">
        <div className="flex rounded-[8px] items-start border border-borderColor">
          <div className="w-[150px] p-5 text-fontColor">Claim Headline</div>
          <div className="flex-1 p-5 text-fontColor font-semibold group-hover:text-white pr-[20px]">
            {theData?.modeloutput?.English.heading}
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="border border-borderColor overflow-hidden rounded-[8px] mt-4 flex flex-col items-center p-[32px]">
            <h4 className="text-[24px] font-bold">Global Claim Tree</h4>
            <div className="flex justify-center mt-[45px] w-full">
              <CountryDataCard item={globalData} />
            </div>
            <Lines />
            <div className="flex justify-between w-full">
              {countriesData.map((item, i) => (
                <CountryDataCard item={item} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-[80px] mb-[32px]">
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8"
          onClick={() => setStage("Review")}
        >
          Back
        </button>
        <button className="border border-borderColor rounded-[4px] p-3 px-8 ml-5">
          Initiate MLR
        </button>
        <button
          className="bg-primaryColor text-white rounded-[4px] p-3 px-8 ml-5"
          onClick={() => setConfirmModal(!confirmModal)}
        >
          Share
        </button>
      </div>
    </div>
  );
};

export default GlobalClaimTree;
