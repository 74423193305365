import React, { useEffect } from "react";

const ViewRationaleModal = ({ setIsViewRationaleClick,setSelected }) => {

   
  return (
    <div className="w-full z-50 h-screen fixed left-1/2 -translate-y-1/2 top-1/2 -translate-x-1/2 flex justify-center items-center">
      <div
        className="w-[400px] p-8 py-8 bg-white shadow-md rounded-[16px] z-50  "
      >
        <div className="flex border-b border-mediumGray pb-8 justify-between">
          <h4 className="text-2xl leading-[28px] font-semibold  ">Rationale</h4>

          <img
            src="./assets/tactic/closeIcon.png"
            alt="closeIcon"
            id="closeIcon"
            className="w-6 h-6 mt-1 cursor-pointer"
            onClick={() => {
              setIsViewRationaleClick(false);
              setSelected("")
            }}
          />
        </div>
        <div>
          <div>
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li className="mt-6">
                <span className="font-bold text-base">
                  Regulatory requirement:
                </span>
                <span className="ml-2">
                  need to include "advanced" based on label
                </span>
              </li>
              <li className="mt-6">
                <span className="font-bold text-base">Market research:</span>
                <span className="ml-2">
                  Emotional tone leads to higher content response
                </span>
              </li>
              <li className="mt-6">
                <span className="font-bold text-base">
                  Historical performance:
                </span>
                <span className="ml-2">
                  Leading with brand name produces higher engagement
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-fontColor opacity-20 w-full h-screen absolute top-0 left-0"></div>
    </div>
  );
};

export default ViewRationaleModal;
