import React from "react";
import Layout from "../components/layouts";
import CreateOrganizationComponents from "../components/pages/CreateOrganizationComponents";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const CreateOrganization = () => {
   const navigate = useNavigate();
  const { user } = useUser();
   if (!user) {
     // Redirect to the sign-in page if orgRole is not available
     navigate("/sign-in");
     return null;
   }

  return (
    <Layout>
      {user?.publicMetadata?.superAdmin && <CreateOrganizationComponents />}
    </Layout>
  );
};

export default CreateOrganization;
