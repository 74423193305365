import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../../App";
import { useOrganizationList } from "@clerk/clerk-react";

const Menu = ({ data, main }) => {
  const location = useLocation();
  const { setActive } = useOrganizationList();



  const { setInputValue } = useContext(Context);


  return (
    <div
      className="grid grid-cols-1 gap-1 "
      onClick={() => {
        setInputValue("");
      }}
    >
      {data.map((item, i) => (
        <Link
          to={item.href}
          className={`${
            location.pathname === item.href
              ? "bg-primaryColor text-white"
              : "bg-none text-fontColor"
          } rounded-[4px] w-full py-[8px] px-[15px] flex items-center hover:bg-primaryColor hover:text-white group`}
          key={i}
        >
          {main && (
            <>
              {location.pathname === item.href ? (
                <img
                  src={item.path1}
                  alt={item.label}
                  className="w-[24px] mr-2 "
                />
              ) : (
                <>
                  <img
                    src={item.path1}
                    alt={item.label}
                    className="w-[24px] mr-2 group-hover:block hidden"
                  />
                  <img
                    src={item.path2}
                    alt={item.label}
                    className="w-[24px] mr-2 group-hover:hidden block"
                  />
                </>
              )}
            </>
          )}
          {!main && (
            <img
              src={item.path}
              alt={item.label}
              className="w-[24px] mr-2 group-hover:hidden block"
            />
          )}
          {item.label}
        </Link>
      ))}
    </div>
  );
};

export default Menu;
