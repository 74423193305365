import {
  useAuth,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import Loader from "../Loader/Loader";
import { BsFillCameraFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { Context } from "../../../App";

const CreateOrganizationModal = ({
  isAddOrganizationClicked,
  setIsAddOrganizationClicked,
  orgData,
  isEditMode,
  orgId,
  image,
  setImage,
  
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organizationName: orgData ? orgData.name : "", // Set to empty string if orgData is not provided
      claim: orgData && orgData.public_metadata.claim === "claim",
      tactic: orgData && orgData.public_metadata.tactic === "tactic",
      smrif: orgData && orgData.public_metadata.smrif === "smrif",
    },
  });
  const auth = useAuth();
  const [organizationName, setOrganizationName] = useState("");
  const [selectedRole, setSelectedRole] = useState("admin");
  const [userList, setUserList] = useState(null);
  const [email, setEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const { setActive } = useOrganizationList();
  const { organization } = useOrganization();
  const [currentImage, setCurrentImage] = useState(null);

  const { userId } = useAuth();

  const {
    organizationListData,
    setOrganizationListData,
    setCurrentOrganizationLogo,
    selectedImage,
    setSelectedImage,
    setAddOrganizationResponse,
    addOrganizationResponse,
    setCurrentLogo,
  } = useContext(Context);

  const createOrganizationWithMetadata = async (requestBody) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}createOrganization`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const orgData = await response.json();

      setActive({ organization: orgData?.id });

      const newData = { organization: orgData };

      setOrganizationListData((prevData) => [newData, ...prevData]);

      if (!isEditMode && !errors.organizationName) {
        addAdmin({
          email_address: email,
          inviter_user_id: userId,
          role: selectedRole,
          orgId: orgData?.id,
          redirect_url: `${process.env.REACT_APP_CLIENT_URL}sign-in`,
        });
      }

      setLoader(false);
      setAddOrganizationResponse(true);
      setCurrentLogo(selectedImage)
      
      return orgData;
      // Save response data in state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}getAllUsers`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const getAllUserList = await response.json();
      setUserList(getAllUserList);

      // Save response data in state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const addAdmin = async (requestBody) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}addUser`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      // const orgData = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const imageUpload = async (orgId) => {
    const formData = new FormData();
    formData.append("userID", auth.userId);
    formData.append("organisationId", orgId);
    formData.append("file", image);
    await fetch(`${process.env.REACT_APP_SERVER_URL}uploadOrgLogo`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
      },
      body: formData,
    });
  };
  const EditOrganisation = async (requestBody) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}updateOrganization`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLERK_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const orgData = await response.json();

      const indexToUpdate = organizationListData.findIndex((obj) => {
        return obj?.organization?.id === orgData?.id;
      });

      if (indexToUpdate !== -1) {
        // Replace the object's data with the newObject's data
        const organization = {
          organization: orgData,
          membership: organizationListData[indexToUpdate].membership,
        };
        organizationListData[indexToUpdate] = organization;
        const updateArray = [...organizationListData];
        updateArray[indexToUpdate] = organization;
        setOrganizationListData(updateArray);
      }
      setLoader(false);
      return orgData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onSubmit = async (data) => {
    if (!selectedImage) {
      toast.error("Add image");
      return;
    }
    const requestBody = {
      name: data.organizationName,
      public_metadata: {
        claim: data.claim ? "claim" : undefined,
        tactic: data.tactic ? "tactic" : undefined,
        smrif: data.smrif ? "smrif" : undefined,
      },
      created_by: auth.userId,
    };
    if (isEditMode) {
      requestBody["orgId"] = orgId;

      const editOrg = await EditOrganisation(requestBody);

      await imageUpload(editOrg.id);
    } else {
      const orgData = await createOrganizationWithMetadata(requestBody);
      if (orgData) {
        const data = await imageUpload(orgData.id);
        //   const orgdata = await getAllOrganization();
        //   if(orgdata){
        //   setCurrentOrganizationLogo(orgdata?.data[0]?.image_url);
        //  }
      }
    }
    setIsAddOrganizationClicked(!isAddOrganizationClicked);
    setOrganizationName("");
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const chooseFile = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        
      };

      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (isEditMode) {
      if (orgData?.logo_url) {setCurrentImage(orgData?.url);}
      else{
        setCurrentImage(selectedImage)
      }
    }
  }, [isEditMode]);

  return (
    <>
      {loader && <Loader />}
      <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <ToastContainer />
        <div
          id="modal"
          className="modal-overlay absolute w-full h-full bg-black opacity-50"
        ></div>
        <div
          className={`modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-md shadow-lg z-50 `}
        >
          <div className=" flex justify-between items-center pr-5 pt-2">
            <h1 className="px-6 mt-2 font-bold text-primaryColor">
              {isEditMode && isEditMode
                ? "Edit Organization"
                : "Add Organization"}
            </h1>
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsAddOrganizationClicked(!isAddOrganizationClicked);
              }}
            >
              <RxCross2 />
            </div>
          </div>
          <div className="relative">
            <div className="px-6 py-2">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden z-50"
                onChange={handleFileChange}
              />

              <BsFillCameraFill
                onClick={chooseFile}
                className="absolute z-50 top-[80px] left-[80px]  cursor-pointer text-xl"
              />
            </div>
            <div className="pl-5 border border-borderColor bg-fontColor">
              {selectedImage || currentImage ? (
                <img
                  src={isEditMode?currentImage:selectedImage ? selectedImage : currentImage}
                  alt="SelectedImage"
                  className="w-[80px] h-[78px] rounded-full absolute object-cover mt-3"
                />
              ) : (
                <div className="w-[80px] h-[78px] rounded-full absolute bg-primaryColor mt-3 pt-6 pl-1 text-white text-sm font-semibold"></div>
              )}
            </div>
          </div>

          <form
            className="modal-content py-4 text-left mt-[80px] px-6 h-1/6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-4">
              <label
                htmlFor="organizationName"
                className="block text-sm font-medium text-gray-600"
              >
                Organization Name
              </label>
              <input
                type="text"
                id="organizationName"
                name="organizationName"
                className="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
                {...register("organizationName", { required: true })}
                onChange={(e) => setOrganizationName(e.currentTarget.value)}
              />
              {errors.organizationName && (
                <span className="text-error font-semibold text-xs">
                  organizationName is required
                </span>
              )}
            </div>
            {!isEditMode && (
              <select
                className="w-full h-[40px]"
                onClick={(event) => {
                  setEmail(event.target.value); // Set the selected email
                }}
              >
                {userList &&
                  userList.map((item) => {
                    return (
                      <option
                        key={item.id}
                        value={item.email_addresses[0].email_address}
                      >
                        {item.email_addresses[0].email_address}
                      </option>
                    );
                  })}
              </select>
            )}
            {!isEditMode && (
              <div className="mb-4">
                <label
                  htmlFor="role"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  {...register("role", { required: true })}
                  value={selectedRole}
                  onChange={handleRoleChange}
                  className="form-select border-lightGreen w-full px-4 py-3 rounded-lg border focus:outline-none focus:shadow-outline"
                >
                  <option value="admin">ADMIN</option>
                  <option value="basic_member">MEMBER</option>
                </select>
                {errors.role && <span>Role is required</span>}
              </div>
            )}
            <div>
              <h1 className="mb-2">Features:</h1>
              <div className="flex items-center gap-5">
                <input
                  type="checkbox"
                  id="claim"
                  name="claim"
                  {...register("claim")}
                />
                <label htmlFor="claim">Claim</label>
              </div>
              <div className="flex items-center gap-5">
                <input
                  type="checkbox"
                  id="tactic"
                  name="tactic"
                  {...register("tactic")}
                />
                <label htmlFor="tactic">Tactic</label>
              </div>
              <div className="flex items-center gap-5">
                <input
                  type="checkbox"
                  id="smrif"
                  name="smrif"
                  {...register("smrif")}
                />
                <label htmlFor="smrif">Smrif</label>
              </div>
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-primaryColor text-white rounded-[4px] hover:bg-blue-600 focus:outline-none mt-4"
            >
              {isEditMode && isEditMode ? "Edit" : "Create Organization"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrganizationModal;
