import React, { useContext, useState } from "react";
import Modal from "./Modal";
import { Context } from "../../../../App";
import CommunityCard from "./CommunityCard";
import RationaleModal from "./RationaleModal";
import PatientCard from "./PatientCard";
import ResearchersCard from "./ResearchersCard";
import SocialMediaCard from "./SocialMediaCard";

const contents = [
  {
    title: "Eyebrow",
    text: "Verzenio + ET in patients with HR+, HER2−, node-positive EBC at high risk of recurrence (Cohort 1)¹*",
  },
  {
    title: "Headline",
    text: "Verzenio + ET in patients with HR+, HER2−, node-positive EBC at high risk of recurrence (Cohort 1)¹*",
  },
  {
    title: "Subheadline",
    text: "N/A",
  },
  {
    title: "Visual Rep.",
    image: "./assets/zoomView.png",
  },
  {
    title: "Body Copy",
    text: "Reduction in risk of metastases continued beyond the 2-year Verzenio treatment period¹",
  },
  {
    title: "Required Disclaimers and/or Material Facts",
    text: "Efficacy analyses in Cohort 1 were not alpha controlled for statistical significance testing Absolute difference was calculated by subtraction of the DRFS rates between the two arms at each year. Results depicted in the KM curve should not be interpreted beyond 48 months.",
  },
  {
    title: "RDMF Override (Affiliate-level modules only)",
    text: "",
  },
  {
    title: "Abbreviations Footnotes ",
    text: "CDK4 & 6=cyclin-dependent kinases 4 and 6; CI=confidence interval; DRFS=distant relapse-free survival; EBC=early breast cancer; ET=endocrine therapy; HER2–=human epidermal growth factor receptor 2–negative; HR=hazard ratio; HR+=hormone receptor–positive; IDFS=invasive disease-free survival; ITT=intent-to-treat *High-risk EBC in Cohort 1 was defined that were ≥5 cm, histological Grade 3, or both.¹",
  },
  {
    title: "Tactic Preview",
    button: "Preview Email",
  },
  {
    data: [78, 98],
  },
];

const options = [
  {
    text: "Submit Option 1 Only",
    value: 1,
  },
  {
    text: "Submit Option 2 Only",
    value: 2,
  },
  {
    text: `Submit Options 1 & 2 (Automatically triggers A/B Test)`,
    value: 3,
  },
];

const Review = () => {
  const {
    setStage,
    localize,
    setLocalize,
    tacticValue,
    selectedSegments,
    selectedCard,
    setSelectedCard,
    referenceDetails,
    data,
    selected,
    reference_dictionary
  } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const [isRationaleClick, setIsRationaleClick] = useState(false);
  const [savedImage, setSavedImage] = useState("")

  return (
    <div className="w-[750px] relative">
      {isOpen && (
        <Modal
          options={options}
          setStage={setStage}
          setIsOpen={setIsOpen}
          setLocalize={setLocalize}
        />
      )}
      {isRationaleClick && (
        <RationaleModal setIsRationaleClick={setIsRationaleClick} />
      )}
      <div className="flex items-center py-[20px]">
        <p className="text-fontColor font-semibold">Create a Tactic</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor font-semibold">Update Tactics</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor">Review</p>
      </div>
      <div className="w-full mt-8">
        <div className="flex flex-col rounded-[8px] items-start border border-borderColor py-3">
          <div className="flex items-start w-full">
            <div className="w-[150px] p-5 py-1 text-fontColor">Segment</div>
            <div className="flex-1 p-5 py-1 text-fontColor group-hover:text-white pr-[20px] font-bold">
              {selectedSegments}
            </div>
          </div>
          <div className="flex items-start w-full">
            <div className="w-[150px] p-5 py-1 text-fontColor">Tactic</div>
            <div className="flex-1 p-5 py-1 text-fontColor group-hover:text-white pr-[20px] font-bold">
              {tacticValue}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-3 mt-4">
          {selectedCard === "community" ? (
            <CommunityCard
              data={contents}
              localize={localize}
              setIsRationaleClick={setIsRationaleClick}
              claim_data={data}
              selected={selected}
              reference_dictionary={reference_dictionary}
              selectedCard={selectedCard}
              setSavedImage={setSavedImage}
              savedImage={savedImage}
              
            />
          ) : selectedCard === "patient" ? (
            <PatientCard
              data={contents}
              localize={localize}
              setIsRationaleClick={setIsRationaleClick}
              referenceDetails={referenceDetails}
              claim_data={data}
              selected={selected}
              reference_dictionary={reference_dictionary}
              selectedCard={selectedCard}
              setSavedImage={setSavedImage}
              savedImage={savedImage}
            />
          ) : selectedCard === "research" || localize ? (
            <ResearchersCard
              data={contents}
              localize={localize}
              setIsRationaleClick={setIsRationaleClick}
              claim_data={data}
              selected={selected}
              reference_dictionary={reference_dictionary}
              selectedCard={selectedCard}
              setSavedImage={setSavedImage}
              savedImage={savedImage}
            />
          ) : (
            <SocialMediaCard
              data={contents}
              localize={localize}
              setIsRationaleClick={setIsRationaleClick}
              claim_data={data}
              selected={selected}
              reference_dictionary={reference_dictionary}
              selectedCard={selectedCard}
              setSavedImage={setSavedImage}
              savedImage={savedImage}
            />
          )}
        </div>
      </div>
      <div className="flex justify-end mt-[80px] mb-[32px] gap-5 text-base font-medium ">
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8 "
          onClick={() => {
            setStage("UpdateTactics");
            setLocalize("");
            setSelectedCard("community")
          }}
        >
          Back
        </button>
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8 "
          onClick={() => setStage("Submitting")}
        >
          Submit to MLR
        </button>
        <button
          className="border border-borderColor rounded-[4px] p-3 px-8 "
          onClick={() => setStage("Submitting")}
        >
          Share
        </button>
        {!localize && (
          <button
            className="bg-primaryColor text-white rounded-[4px] p-3 px-8 "
            onClick={() => {
              setIsOpen(true);
              setSelectedCard("");
            }}
          >
            Localize
          </button>
        )}
      </div>
    </div>
  );
};

export default Review;
