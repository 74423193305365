import React from "react";

const Completed = () => {
  return (
    <div className="w-[750px]  rounded-2xl mt-[80px]">
      <div className="w-full border border-borderColor rounded-2xl p-[32px] h-[450px] flex flex-col justify-center items-center ">
        <div className="">
          <img src="./assets/success.png" alt="success" />
        </div>
        <h4 className="text-[24px] mt-[35px] font-bold">Complete!</h4>
      </div>
    </div>
  );
};

export default Completed;
