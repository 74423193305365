import React, { createContext, useState } from "react";

import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import CreateClaim from "./pages/CreateClaim";
import CreateTactic from "./pages/CreateTactic";
import CreateImage from "./pages/CreateImage";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignIn,
  SignUp,
  SignedIn,
  SignedOut,
  useUser,
} from "@clerk/clerk-react";
import CreateOrganization from "./pages/CreateOrganization";
import AddUser from "./pages/AddUser";

export const Context = createContext(null);
const valueMapping = {
  "REFERENCE SET 1": "REFERENCE SET 1",
  "REFERENCE SET 2": "REFERENCE SET 2",
  "Generate global claim options based on EBC: Unmet need, post 2-year treatment.":
    "REFERENCE SET 1",
  "Generate a set of claims based on the latest data.": "REFERENCE SET 2",
};

const App = () => {
  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
  }
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  const navigate = useNavigate();


  const router = (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <Routes>
        <Route path="/" element={<Navigate replace to="/sign-in" />} />
        <Route
          path="/sign-up/*"
          element={
            <div className="flex flex-col  items-center justify-center h-screen">
              {/* <div>
                <img
                  src="./assets/Home/Logo1.png"
                  alt="maestro "
                  className="h-[20px]"
                />
              </div> */}
              <div className="mt-5">
                <SignUp redirectUrl="/home" routing="path" path="/sign-up" />
              </div>
            </div>
          }
        />

        <Route
          path="/sign-in/*"
          element={
            <div className="flex flex-col  items-center justify-center h-screen">
              <div className="mt-5">
                <SignIn redirectUrl="/home" routing="path" path="/sign-in" />
              </div>
            </div>
          }
        />

        <Route
          path="/home"
          element={
            <>
              <SignedIn>
                <Home />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
        <Route path="/create_claim" element={<CreateClaim />} />
        <Route path="/create_tactic" element={<CreateTactic />} />
        <Route path="/create_image" element={<CreateImage />} />
        <Route path="/create_organization" element={<CreateOrganization />} />
        <Route path="/add_user" element={<AddUser />} />
        {/* <Route path="/change_organization" element={<ChangeOriganization />} /> */}
      </Routes>
    </ClerkProvider>
  );

  const reference_dictionary = [
    {
      PubID: "REF-56908",
      Abbreviation: "Abdel-Razeq H et al Drug Des Devel Ther. 2022",
    },
    {
      PubID: "REF-54984",
      Abbreviation: "Harbeck N et al Annal of Oncol 2021",
    },
    {
      PubID: "REF-49941",
      Abbreviation: "Johnston SRD et al Journal of Clinical Oncol 2020",
    },
    {
      PubID: "REF-46154",
      Abbreviation: "Sledge GW Jr et al JAMA Oncol 2019",
    },
    {
      PubID: "REF-40577",
      Abbreviation: "Di Leo A et al NPJ Breast Cancer 2018",
    },
    {
      PubID: "REF-37579",
      Abbreviation: "Sledge GW Jr et al Clin Oncol 2017",
    },
  ];

  const [inputValue, setInputValue] = useState("");
  const [stage, setStage] = useState("");
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState(null);
  const [localize, setLocalize] = useState("");
  const [referenceDetails, setReferenceDetails] = useState("");
  const [tacticValue, setTacticValue] = useState("");
  const [selectedSegments, setSelectedSegments] = useState("Late Majority");
  const [selectedCard, setSelectedCard] = useState("community");
  const [organizationListData, setOrganizationListData] = useState(null);
  const [currentOrganizationLogo, setCurrentOrganizationLogo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [addOrganizationResponse, setAddOrganizationResponse] = useState(false);
  const [hasOrganizationLoaded, setHasOrganizationLoaded] = useState(true);
  const [currentLogo, setCurrentLogo] = useState("");
  const [superAdminOrgData, setSuperAdminOrgData] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue) return;

    let reference_value = valueMapping[inputValue];
    setReferenceDetails(reference_value);
    setStage("Processing");
  };

  return (
    <Context.Provider
      value={{
        handleSubmit,
        inputValue,
        setInputValue,
        stage,
        setStage,
        setData,
        data,
        selected,
        setSelected,
        localize,
        setLocalize,
        referenceDetails,
        setReferenceDetails,
        tacticValue,
        setTacticValue,
        selectedSegments,
        setSelectedSegments,
        selectedCard,
        setSelectedCard,
        reference_dictionary,
        organizationListData,
        setOrganizationListData,
        setCurrentOrganizationLogo,
        currentOrganizationLogo,
        setSelectedImage,
        selectedImage,
        setAddOrganizationResponse,
        addOrganizationResponse,
        setCurrentLogo,
        currentLogo,
        hasOrganizationLoaded,
        setHasOrganizationLoaded,
        setSuperAdminOrgData,
        superAdminOrgData,
      }}
    >
      {router}
    </Context.Provider>
  );
};

export default App;
