import React from "react";

const CountryDataCard = ({ item }) => {
  return (
    <div className="w-[23%] rounded-[8px] overflow-hidden h-max border border-borderColor">
      <div className="bg-[#80D5E3] w-full p-4 flex items-center h-max">
        <img src={item.image} alt={item.country} />
        <p className="ml-2">{item.country}</p>
      </div>
      <p className="bg-[#E0F5F8] p-3 h-max">{item.content}</p>
      <div className="">
        {item?.data.map((d, i) => (
          <div
            key={i}
            className="flex items-center odd:bg-[#F5F6F7] even:bg-white p-4"
          >
            <p className="w-[30%]">{d.one}</p>
            <p className="flex-1">{d.two}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountryDataCard;
