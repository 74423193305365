import React from "react";
import { Link } from "react-router-dom";

const HomeComponents = () => {
  return (
    <div className="h-[calc(100vh_-_90px)] flex justify-center items-center">
      <div className="w-[750px] h-[400px] rounded-[4px] border border-borderColor px-[32px] py-0">
        <div className="py-[48px]">
          <img src="./assets/MaestroHorizontal.png" className="h-[28px]" />
        </div>
        <div className="flex justify-between">
          <Link
            to={"/create_claim"}
            className="w-[327px] h-[225px] rounded-[12px] bg-[#E0F5F8] flex justify-center items-center"
          >
            <div className="flex flex-col items-center">
              <img
                src="./assets/Home/plus.png"
                alt="plus icon"
                className="w-[48px]"
              />
              <h4 className="text-[20px] font-bold mt-[15px]">
                Create a Claim
              </h4>
            </div>
          </Link>
          <Link
            to={"/create_tactic"}
            className="w-[327px] h-[225px] rounded-[12px] bg-[#E2EBF9] flex justify-center items-center"
          >
            <div className="flex flex-col items-center">
              <img
                src="./assets/Home/plus.png"
                alt="plus icon"
                className="w-[48px]"
              />
              <h4 className="text-[20px] font-bold mt-[15px]">
                Create a Tactic
              </h4>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeComponents;
