import React, { useContext } from "react";
import { Context } from "../../../App";

const CreateImage = () => {
  const { setStage } = useContext(Context);
  return (
    <div className="w-[1200px]">
      <div className="flex items-center py-[20px]">
        <p className="text-fontColor font-semibold">Create an Image</p>
        <img src="./assets/CaretRight.png" alt="Caret Right" className="mx-1" />
        <p className="text-fontColor">Image Result</p>
      </div>
      <div className="w-full mt-8 rounded-md bg-[#D9D9D9] h-[580px]"></div>

      <div className="flex justify-end mt-[80px] mb-[32px]">
        <button
          className="border border-borderColor rounded-[8px] p-3 px-8"
          onClick={() => setStage("")}
        >
          Back
        </button>
        <button className="border border-borderColor rounded-[8px] p-3 px-8 ml-5">
          Initial MLR
        </button>
        <button className="bg-secondaryColor text-white rounded-[8px] p-3 px-8 ml-5">
          Share
        </button>
      </div>
    </div>
  );
};

export default CreateImage;
